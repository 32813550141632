@import "src/styles/vars";
.resources {
  .title {
    font-size: 16px;
    line-height: 20px;
    color: @black;
    padding-bottom: 10px;
    display: flex;
    width: 100%;
  }
  .items {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .item {
    width: 49%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
    cursor: pointer;
    transition: 0.3s ease-out all;
    text-align: center;
    span {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &:hover {
      transform: scale(1.1);
      small {
        color: @blue;
      }
    }
    img {
      width: 30px;
      height: 30px;
      overflow: hidden;
      display: block;
      border-radius: 50%;
    }
    small {
      font-size: 10px;
      line-height: 13px;
      color: rgba(15, 16, 17, 0.44);
      transition: 0.5s ease-out all;
    }
  }
}
@primary-color: #0061D9;