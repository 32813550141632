@import 'src/styles/vars';

.sorting-content {
	margin-top: 30px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 15px;
	font-size: 16px;
	margin-bottom: 15px;
	.sorting__item {
		max-width: 250px;
		display: flex;
		flex-direction: column;
		&__actions-btn {
			display: flex;
			align-items: center;
			margin-top: 15px;
			margin-left: 10px;
			@media (max-width: 767px) {
				margin-left: 0;
			}
			.ant-btn {
				height: 39px;
				border-radius: 5px;
			}
		}
		&--icon {
			color: #dadada;
		}

		&:last-child {
			margin-right: 0;
		}
	}
}

.sorting__item {
	.ant-input-affix-wrapper {
		border-radius: 5px;
	}

	.ant-input-affix-wrapper,
	.ant-input-search-button {
		height: 42px;
	}

	.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
		height: 42px;
	}

	.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
		height: 42px;
		border-radius: 5px;
	}
	.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		min-height: 42px;
		height: 42px;
		border-radius: 5px;
	}
	.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		min-height: 42px;
		height: 42px;
		border-radius: 5px;
	}

	.ant-select-single .ant-select-selector .ant-select-selection-item,
	.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
		line-height: 42px;
	}

	.ant-switch {
		background-color: rgba(@green-yellow, 0.3);
	}

	.ant-switch-checked {
		background-color: @green-yellow;
	}
}

@primary-color: #0061D9;