@import "src/styles/vars";
.ant-table-pagination > * {
  border-radius: 40%;
}
.ant-drawer {
  top: 100px;
}
.ant-table-column-sort {
  background: rgba(@blue, 0.1) !important;
}
.ant-pagination-item-active {
  background-color: @green-yellow;
  border-color: transparent;
  a {
    color: #ffffff;
    transition: 0.3s ease-out all;
    &:hover {
      color: @dark-blue;
    }
  }
}
.ant-pagination-options {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px;
  }
}
.ant-pagination-item {
  border-radius: 40%;
}
.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
  border: none;
  color: #000;
}


.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus-visible .ant-pagination-item-link {
  border: none;
  color: #000;
}
.ant-back-top {
  right: 30px;
}
@media (max-width: 500px) {
  .ant-drawer {
    top: 73px;
  }
}
.modal-form-error {
  width: 450px !important;

  ul {
    margin: 0;
    padding: 0;
  }
}
sup {
  top: auto;
}
