@import "src/styles/vars";
.ModalDuplicate {
  .ant-modal-header {
    border-bottom: none;
  }
  .form-group .ant-select-multiple {
    height: auto;
  }
  .ant-modal-body {
    padding-top: 0;
    .title {
      font-size: 16px;
      color: @blue;
    }
  }
  .ant-modal-title {
    color: @main;
    font-size: 25px;
    line-height: 29px;
  }
  &__body {
    &-action {
      margin-top: 15px;
      font-size: 20px;
      cursor: pointer;
      color: @blue;
      transition: 0.3s ease-in-out all;
      .anticon {
        margin-left: 5px;
        transition: 0.3s ease-in-out transform;
      }
      &:hover {
        color: @dark-blue;
        .anticon {
          transform: rotate(180deg);
        }
      }
    }
    &__select {
      width: 100%;
    }
    &-item {
      .form-group {
        margin-top: 5px;
      }
      span {
        color: @main;
        font-size: 18px;
        line-height: 20px;
      }
    }
  }
}
@primary-color: #0061D9;