@import "src/styles/vars";
.item {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.icon {
  color: @gray-separator;
  &:global(.red) {
    color: @coral;
  }
}
@primary-color: #0061D9;