.tag-input {
	width: 130px;
	min-height: 30px !important;
	max-height: 30px;

	margin-right: 8px;
	vertical-align: top;
	border-radius: 10px;
}
.ant-tag {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 30px;
	min-width: 110px;
	border-radius: 10px;
}
.edit-tag {
	color: black;
	margin-top: 3px;
	margin-bottom: 3px;
	.ant-checkbox-wrapper {
		margin-left: 10px;
		margin-right: 2px;
	}
}
.field-organization__tags {
	.tags-droppable {
		display: flex;
		flex-wrap: wrap;
	}

}
.ant-tag-close-icon {
	color: black!important;
}

@primary-color: #0061D9;