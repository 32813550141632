.modal-user-profile {
  &__organizations {
    padding-top: 20px;
    padding-left: 35px;
    .field-organization__organization {
      &__text {
        margin-left: 20px;
        font-weight: 400;
        font-size: 17px;
        line-height: 22px;
        color: #B2B2B6;
        &__inn {
          margin-left: 20px;
          font-weight: 400;
          font-size: 12px;
        }
      }
    }
    .field-organization__organization,
    .field-organization__phone,
    .field-organization__email {
      position: relative;
      margin-bottom: 40px;

      &--wrapper {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        p {
          min-width: 105px;
          font-size: 16px;
          font-weight: 400;
          margin: 0;
        }
      }
    }
  }
}
@primary-color: #0061D9;