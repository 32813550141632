@import "src/styles/vars";
.modal-form-no-save {
  width: 550px!important;
  .ant-modal-title {
    font-size: 18px;
    color: @black;
  }
  &__content {
    display: flex;
    flex-direction: column;
    padding: 10px;
    span {
      display: block;
      font-size: 17px;
      line-height: 24px;
    }
    span {
      color: @main;
    }
  }
  .ant-modal-footer {
    .ant-btn {
      height: 35px;
      span {
        font-size: 14px;
        line-height: 14px;
      }
    }
  }
}

@primary-color: #0061D9;