@import "src/styles/vars";

.modal {
  :global(.ant-modal-header) {
    border-bottom: none;
  }
  :global(.ant-modal-body) {
    padding: 0;
  }
}
@primary-color: #0061D9;