@import "src/styles/vars";
.Template {
  //width: 100%;
}
.Template__container {
  display: flex;
  align-items: center;
  min-width: 360px;
  @media (max-width: @sm) {
    flex-direction: column;
    align-items: flex-start;
  }
}
.Template {
  &-download ,
  &-import {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    line-height: 16px;
    color: @blue;
    @media (min-width: 1710px) {
      margin-right: 5px;
    }
    span {
      margin-left: 3px;
    }
    span > svg {
      color: @blue;
      width: 25px;
      height: 25px;
    }
    &:hover {
      color: @dark-blue;
    }
  }
  &-import {
    margin-top: 4px;
    @media (max-width: @sm) {
      margin-top: 15px;
    }
    span.ant-upload {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 16px;
      color: @blue;
      &:hover {
        color: @dark-blue;
      }
    }
  }

  .error-btn {
    margin-left: 20px;
    color: @blue;
    &:hover {
      color: @dark-blue;
    }
  }
}
.Template-modal {
  .ant-modal-body p {
    font-size: 16px;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1327px) {
  .Template {
    &__file {
      margin-left: 10px;
    }
  }
}
