.container {
  width: 100%;
}
.image {
  aspect-ratio: 16/9;
  width: 100%;
  object-fit: contain;
}
.error {
  width: 70px;
  height: 70px;
  position: relative;
  :global(.ant-empty-img-default) {
    width: 70px;
    height: 70px;
    z-index: 1;
  }
  :global(.ant-empty-description) {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    font-size: 9px;
  }
}
@primary-color: #0061D9;