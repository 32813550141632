@import "src/styles/vars";

.action-cell {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0;
  .anticon {
    font-size: 20px;
    transition: 0.3s ease-in all;
  }
  &__download,
  &__edit,
  &__delete,
  &__send {
    cursor: pointer;
    > * {
      pointer-events: none;
    }
    &:hover {
      .anticon {
        transform: scale(1.1);
      }
    }
  }
  &__download {
    margin-left: 10px;
    color: @blue;
  }
  &__edit {
    margin-right: 10px;
    color: @blue;
    &.disabled {
      color: @gray-background;
    }
  }
  &__delete {
    color: @coral;
    &.disabled {
      color: @gray-background;
    }
  }
  &__send {
    color: @light-orange;
  }
}


@primary-color: #0061D9;