@import "src/styles/vars";
.modal-content-format {
  width: 1050px!important;
  h1 {
    color: @dark-blue;
    font-weight: 700;
    font-size: 14px;
  }
}

@primary-color: #0061D9;