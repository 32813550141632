@import "src/styles/vars";

.name-cell-with-count {
  &__wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &__amount {
    display: flex;
    align-items: center;
    justify-content: center;
    color: @blue;
    border: 1px solid  #F0F0F0;
    background-color:  #F0F0F0;
    margin-left: 5px;
    margin-right: 3px;
    width: 18px;
    height: 18px;
    font-size: 9px;
    border-radius: 50%;
    flex-shrink: 0;
    cursor: pointer;
    z-index: 2;
  }
}

@primary-color: #0061D9;