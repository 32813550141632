@import 'src/styles/vars';

.modal-deadlines {
  &__body {
    padding: 10px;
  }
  &__item {
    margin-bottom: 12px;
    padding: 15px;
    background-color: #F9FBFD;
    h3 {
      font-weight: 400;
      font-size: 12px;
      color: @dark-blue;
    }
    &__block {
      padding: 10px 25px;
      display: flex;
      justify-content: space-between;
      span {
        color: @main;
      }
      span:nth-child(2) {
        color: @green;
      }
    }
  }
}

@primary-color: #0061D9;