@import '~antd/dist/antd.compact';
@import 'styles/mixins/transition';
@import 'styles/fonts';
@import 'styles/vars';
@import 'styles/input';
@import 'styles/tables';
@import 'styles/popover';
@import "styles/ant";
@import "styles/quill";
@import "styles/templayte-buttons-import-export";
@import '~react-quill/dist/quill.snow.css';

@userfont: golos, sans-serif; // User Font Family
@systemfont: golos, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif; // System Font Family


* {
  font-family: @systemfont;
}

body {
  font-family: @userfont,
  sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}
h1, h2 {
  color: @black;
  font-weight: 500;
}
h1 {
  font-size: 32px;
  line-height: 38px;
}
h2 {
  font-size: 24px;
  line-height: 28px;
}
h3 {
  font-size: 21px;
  line-height: 30px;
}
p {
  font-size: 18px;
  line-height: 21.6px;
  color: @main;
}
.img {
  max-width: 100%;
  display: block;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
main {
  position: relative;
  height: 100%;
  width: 100%;
  transition: all 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.spin-suspense {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rc-scrollbars-track {
  background-color: rgba(@blue, 20%) !important;
}
.rc-scrollbars-thumb {
  background-color: rgba(@blue, 0.8) !important;
}
.width100 {
  width: 100%;
}
.wrapper {
  width: 100%;
  margin: 0 auto;
}

@media (min-width: 576px) {
  .wrapper {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .wrapper {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .wrapper {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .wrapper {
    max-width: 1140px;
  }
}

@media (min-width: @xl) {
  .wrapper {
    max-width: 1120px;
  }
}
@media (min-width: @xxl) {
  .wrapper {
    max-width: 1520px;
  }
}


@media (max-width: @sm) {
  .margin-sm-top15 {
    margin-top: 15px;
  }

  .margin-md-top15 {
    margin-top: 15px;
  }
}
.overflow--hidden {
  overflow: hidden;
}
@primary-color: #0061D9;