@import "src/styles/vars";
.container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.chosen {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 4px;
}
.select {
  width: 250px;
}
.timeCodesList {
  margin-top: 20px;
  padding: 0;
  list-style: none;
  color: #fff;
  .timeCode {
    padding: 0 0 0 5px;
    margin: 10px 0 0;
    font-size: 14px;
    .time {
      color: @blue;
      transition: 0.3s ease all;
      cursor: pointer;
      &:hover {
        color: @dark-blue;
      }
    }
  }
}

@primary-color: #0061D9;