@import "src/styles/vars";
.projectsPage {
  min-height: calc(100vh - 101px);
  padding: 30px 50px;
  @media (max-width: @sm) {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .ant-row {
    width: 100%;
  }

  &__topNavigations {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    @media (max-width: @md) {
      padding: 15px;
    }
    .left {
      display: flex;
      flex-wrap: wrap;
      margin-top: 15px;
    }
    .right {
      margin-top: 15px;
    }
  }

  .Template {
    display: flex;
    flex-direction: row;
    margin-left: 30px;
    @media (max-width: @md) {
      margin-left: 0;
      margin-top: 15px;
    }
    @media (max-width: @sm) {
      flex-direction: column;
    }
  }
  .table-project {
      margin-top: 15px;
    }
    &__btn {
    .ant-btn {
      font-size: 35px;
      line-height: 35px;
      height: auto;
      transition: 0.3s ease-in-out all;
      .anticon {
        margin-left: 15px;
        font-size: 30px;
      }
      &:hover {
        color: @dark-blue;
      }
    }
  }
  &__tags {
    @media (max-width: @sm) {
      flex-wrap: wrap;
    }
    &--first {
      margin-right: 7px;
      margin-left: 15px;
      @media (max-width: @sm) {
        margin-left: 0;
        margin-top: 15px;
        margin-bottom: 15px;
      }
    }
  }
  &__tags,
  &__actions {
    display: flex;
    align-items: center;
    @media (max-width: @md) {
      width: 100%;
      margin-top: 15px;
    }
    &-select {
      margin-top: 0;
      margin-right: 15px;
      .ant-select-selector {
        border-radius: 5px!important;
      }
    }
    .ant-select {
      min-width: 130px;
    }
    .ant-btn {
      height: 39px;
      margin-right: 15px;
      border-radius: 5px;
    }

  }
  &__btn {
    .ant-btn {
      font-size: 13px;
      border-radius: 5px;
      &:hover {
        color: @white;
      }
    }
  }

}
.projectPage {
  &__navigation {
    max-width: calc(100% - 5px);
  }
}
@media (max-width: 760px) {
  .projectsPage {
    height: 100%;
    padding: 30px 0;
    &__tags,
    &__actions {
      justify-content: flex-start;
    }
  }
}
.projectPage {
  &-create {
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
  }
  &-form {
    margin-top: 20px;
  }
  &-project {
    padding-bottom: 50px;
    min-height: calc(100vh - 116px);
    .projectPage__content {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
  &__top {
    margin-top: 15px;
    margin-left: 50px;
    margin-right: 50px;
    @media (max-width: @sm) {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }
  &__content {
    margin-top: 30px;
  }
  &__options {
    padding-right: 50px;
    &-items {
      font-size: 30px;
      display: flex;
      div {
        margin-right: 20px;
        cursor: pointer;
        transition: 0.3s ease-in-out all;
        color: @blue;
        &.delete {
          color: @coral;
        }
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          transform: scale(1.2);
          color: @dark-blue;
          &.delete {
            color: @dark-coral;
          }
        }
      }

    }
  }
}
.projectPage__topNavigation {
  max-width: 1193px;
  margin: 30px 50px 0;
  @media(max-width: @sm) {
    margin: 30px 10px 0;
  }
}
@media (max-width: 340px) {
  .projectPage {
    &-create {
      padding: 0 15px;
    }
  }
}
@media (max-width: 340px) {
  .projectsPage {
    &__tags,
    &__actions {
      .ant-select {
        width: 160px;
      }
    }
  }
}
@media (min-width: 540px) {
  .projectsPage {
    &__tags,
    &__actions {
      .ant-select {
        min-width: 280px;
      }
    }
  }
}


@primary-color: #0061D9;