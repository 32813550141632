@import 'src/styles/vars';
.functionality_info {
	padding-bottom: 100px;
	:global(.ant-collapse-header) {
		font-weight: 600;
		color: @blue!important;
		user-select: none;
	}
	:global(.ant-collapse) {
		border: 1px solid white !important;
	}
	:global(.ant-collapse-header) {
		background-color: white !important;
	}
	:global(.ant-collapse-item) {
		border: 1px solid white !important;
	}

}
.icons {
	display: inline-block;
	svg {
		margin-right: 5px;
	}
}
.second_collapse {
	:global(.ant-collapse-header) {
		font-weight: 600;
		color: @main!important;
	}
}
.point {
	display: flex;
	align-items: center;
	gap: 5px;
}
.text {
	color: @main;
	margin-bottom: 10px;
}
.important {
	color: @coral;
}
.img_wrapper {
	display: block;
}
.img {
	max-width: 100%;
	max-height: 400px;
	height: auto;
	margin-bottom: 10px;
}

@primary-color: #0061D9;