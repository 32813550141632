@import "src/styles/vars";
.modal-reach-statistic {
  .ant-modal-title {
    color: @blue;
  }
  &__percent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F7F7F8;
    padding: 0 10px;
    margin-bottom: 15px;
    &__percent.coral {
      color: @coral;
    }
    &__percent {
      font-weight: 700;
      font-size: 20px;
      color: @green;
    }
    &_text {
      font-size: 12px;
    }
  }
  &__title {
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__title,
    &_text {
      display: flex;
      font-size: 14px;
      color: @black;
    }
  }
  &__statistic {
    padding: 0 10px;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 2.5fr 1.5fr 1fr;
    grid-template-rows: 1fr;
    gap: 0 0;
    grid-template-areas: '. . . .';
    &--bg {
      background-color: #F7F7F8;
    }
    &__amount {
      display: flex;
      justify-content: center;
      color: @main;
      font-size: 12px;
    }
    &__text {
      display: flex;
      align-items: center;
      min-width: 100px;
      font-size: 8px;
      color: @gray;
    }
    &__date {
      color: @main;
      font-size: 14px;
    }
  }
}
@primary-color: #0061D9;