@import "src/styles/vars";
.container {
  padding: 30px;
}
.title {
  :global(h2) {
    font-size: 20px;
    line-height: 24px;
    color: @main;
  }
}
.table {
  margin-top: 30px;
}
@primary-color: #0061D9;