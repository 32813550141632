@import 'src/styles/vars';

.confirmPage {
	background: @white;
	height: 100%;
	min-height: 100vh;
	color: @base-color;
	overflow: hidden;
	.btn-disabled {
		background-color: @gray-background!important;
		border-color: @gray-background!important;
	}
	&__wrapper {
		position: relative;
		margin-left: 18px;
	}
	&__star {
		top: 20px;
		left: -27px;
		position: absolute;
		font-size: 14px;

		svg {
			color: @green-yellow;
		}

	}
	&__header {
		height: auto;
		min-height: 64px;
		padding-top: 15px;
		padding-bottom: 15px;
		background: @white;
		color: @blue;
		display: flex;
		justify-content: space-between;
		&-logo {
			margin-left: -45px;
			margin-top: -36px;
			display: block;
			cursor: pointer;
		}
		&-link {
			font-size: 22px;
			line-height: 26px;
			color: @blue;
			display: flex;
			align-items: center;
			svg {
				width: 100px;
				height: 50px;
				color: @blue;
			}
		}
	}
	&__content {
		padding: 0 50px;

		h1 {
			font-size: 30px;
			line-height: 35px;
			color: @blue;
			//margin-top: 50px;
			margin-bottom: 50px;
		}

		p {
			font-size: 20px;
			line-height: 40px;
			color: @main;
			margin-top: 60px;
			margin-bottom: 20px;
		}
		.confirm-receiving__emails {
			display: flex;
			width: 93%;
			align-items: center;
			margin-bottom: 10px;
			padding-left: 20px;
			min-height: 39px;
			background: #F5F5F5;
			border: 1px solid #F5F5F5;
			color: @black;
			font-weight: 400;
			font-size: 20px;
			line-height: 20px;
		}

		.btn {
			font-size: 80px;
			color: @blue;
			cursor: pointer;
		}

		img {
			-moz-user-select: none;
			-webkit-user-select: none;
			-ms-user-select: none;
			user-select: none;
			pointer-events: none;
			display: block;
			margin: 0 auto;
		}

		.confirm-data {
			display: flex;
			flex-direction: row;
			margin-top: 70px;

			&--paragraph {
				font-size: 30px;
				line-height: 36px;
				color: @black;
				margin-top: -12px;
				z-index: 10;
			}
			&--text {
				display: inline;
				color: @blue !important;
			}
			.ant-checkbox {
				border-radius: 2px;
				border: 1px solid white;
				top: 0;
				margin-right: 10px;

				input {
					font-size: 40px;
				}
			}
		}
		.confirm-receiving {
			.ant-checkbox {
				border-radius: 2px;
				border: 1px solid white;
				top: 0;

				input {
					font-size: 40px;
				}
			}
		}
	}
	&__footer {
		background: @white;
		color: @black;
		font-size: 16px;
		display: flex;
		justify-content: space-between;
		.link {
			color: @blue;
			cursor: pointer;
			font-size: 40px;
			margin-bottom: 50px;
		}
	}
	&__logout {
		margin-top: 20px;
		border-radius: 5px;
		margin-right: 20px;
		&__links {
			display: flex;
			align-items: flex-end;
			.link--support {
				cursor: pointer;
				line-height: 26px;
				a {
					font-size: 16px;
					color: @main;
					line-height: 20px;
					&:hover {
						color: @dark-blue;
					}
				}
			}
		}
			span {
				font-weight: bold!important;
				font-size:11px ;
				color: @focus;
			}

		&:hover {
			transform: scale(1.1);
		}
	}
}

@media (max-width: 766px) {
	.confirmPage__content img {
		display: none;
	}

	.confirmPage__header {
		flex-wrap: wrap;
		&-logo {
			margin-bottom: 20px;
		}
	}
	.confirmPage__footer {
		flex-wrap: wrap;
		.link {
			margin-bottom: 20px;
			margin-right: 50px;
		}
	}
}

.confirmPage {
	&__wrapper {
		margin-bottom: 20px;
	}
	&__content {
		display: flex;
		flex-direction: column;
		padding: 10px 20px 10px 50px;
		span {
			display: block;
			font-size: 18px;
			line-height: 24px;
			color: @gray;
		}
		input {
			width: 275px;
			margin-top: 15px;
			height: 40px;
		}

		&--mail {
			position: relative;
			margin-bottom: 70px;
			.checkCircle {
				display: inline-flex;
				flex-wrap: wrap;
				align-self: flex-end;
				&__text {
					font-size: 12px;
					color: @green;
				}
				svg {
					color: @green;
					margin-right: 5px;
					margin-left: 31px;
					font-size: 22px;

				}
			}
			> div {
				display: flex;
				flex-wrap: wrap;
				align-items: baseline;

				.wrapper__confirmPage {
					position: relative;
					display: flex;
					justify-content: center;
					align-items: baseline;
					margin-left: 10px;
					.list__item__wrapper__confirm--textOne {
						position: absolute;
						content: '';
						width: 136%;
						font-size: 12px;
						top: 47px;
						color: @gray-background;
					}
					.list__item__wrapper__confirm--textTwo {
						position: absolute;
						cursor: pointer;
						content: '';
						width: 276px;
						font-size: 10px;
						top: 47px;
						left: 14px;
						z-index: 100;
						color: @blue;
					}
				}
			}
			input {
				height: 31px;
				border-radius: 3px;
			}
			.content-title {
				margin-top: 40px;
				font-weight: bold;
				font-size: 16px;
				color: @black;
			}
			p {
				display: inline-block;
				font-size: 17px;
				width: 275px;
				color: rgba(@blue, 0.49);
			}

			.ant-btn-primary {
				background: @blue;
				border-color: @blue;
			}

			.ant-btn {
				margin-left: 22px;
				height: 31px;
				border-radius: 4px;

				span {
					color: @text-color-alt;
					font-size: 11px;
					line-height: 10px;
				}
			}

			.input__confirm {
				margin-left: 30px;
				width: 120px;
			}
			.btn-confirm {
				width: 79px;
				height: 31px;
				border-radius: 3px;
			}
			.ant-input[disabled] {
				color: #000000;
			}
		}
		&--mail {
			.content-title {
				margin-top: 0;
			}
		}

	}
	.ant-modal-footer {
		.ant-btn {
			height: 35px;
			margin-bottom: 5px;
			span {
				font-size: 14px;
				line-height: 14px;
			}
		}
	}
	&__nowrap {
		flex-wrap: nowrap !important;
	}
	&__logout {
		span {
			color: @black;
		}
	}
}

@media (max-width: 675px) {
	.confirmPage {
		&__content {
			&--mail {
				.wrapper__confirmPage {
					margin-top: 20px;
				}
				> div {
					flex-wrap: wrap;
				}
			}
		}
	}
}

@media (max-width: 500px) {
	.confirmPage__content {
		padding: 0 15px;
	}
	.confirmPage__content h2 {
		font-size: 28px;
		line-height: 32px;
		margin-top: 30px;
	}
}

@primary-color: #0061D9;