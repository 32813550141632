.card {
  margin: 0 auto;
  height: 285px;
  width: 237px;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  &__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    z-index: 2;
  }

  &__content {
    position: relative;
    height: 100%;
    z-index: 4;

    > * {
      position: relative;
      z-index: 2;
    }

    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
  }

  &__header {
    .title {
      font-size: 22px;
      font-weight: 800;
      line-height: 24px;
      text-align: left;
      word-wrap: break-word;
      hyphens: auto;
    }

    .status {
      font-size: 12px;
      font-weight: 700;
      line-height: 12px;
      text-align: left;
      margin-top: 10px;
      color: rgba(249, 249, 249, 0.7);
    }
  }

  &__footer {
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    text-align: left;

    .anticon {
      margin-right: 10px;
    }
  }
}
@primary-color: #0061D9;