@import "src/styles/vars";
.IndicatorsContent {
  .item {
    .ant-radio-group {
      width: 100%;
      display: flex;
      @media (max-width: @sm) {
        flex-direction: column;
        font-size: 14px;
      }
    }
    .form-group--radio .ant-radio-group {
      flex-wrap: nowrap;
    }
    .form-group--radio .ant-radio-wrapper {
      flex: 1;
      @media (max-width: @sm) {
        width: 100%;
      }
    }
    .form-group--radio .ant-radio + span {
      font-size: 10px;
      @media (max-width: 680px) {
        font-size: 14px;
      }
    }
  }
}
@primary-color: #0061D9;