@import "src/styles/vars";

.title {
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  color: @main;

}
.text {
  text-align: center;
  font-weight: 400;
  font-size: 13px;
  color: @gray;
}

.modal_support {
  .radio {
    display: flex;
    justify-content: space-around;
  }
  .radio input[type="radio"] {
    display: none;
  }
  .radio label {
    width: 100%;
    text-align: center;
    cursor: pointer;
    font-weight: 400;
    font-size: 18px;
    color: @gray;
  }

  .radio input[type="radio"]:checked + label {
    border-bottom: 2px solid @focus;
    color: @main;
  }
}
.email {
  width: 100%;
  display: flex;
  justify-content: center;
}
.telegram {
  width: 100%;
  display: flex;
  justify-content: center;
}

.email_body {
  span {
    svg {
      font-size: 25px;
    }
  }
}
.email_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}
.email_body_text {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 50px;
  span {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    margin-right: 5px;
    display: flex;
    align-items: center;
    color: #0061D9;
  }
  a {
    display: flex;
    align-items: center;
  }
}
.telegram_body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  img {
    width: 140px;
    height: 140px;
  }
}

.icon {
  margin-top: 40px;
  color: var(--fa-primary-color, #005bff);
  opacity: var(--fa-primary-opacity, 0.2);
  font-size: 133px;
}
@primary-color: #0061D9;