@import "src/styles/vars";

.userForm {
  &__title {
    font-size: 20px;
    font-weight: 500;
  }
  &__label {
    font-size: 16px;
  }
  &__text {
    font-size: 14px;
  }
  &__link {
    font-size: 20px;
    transition: 0.3s ease all;
    &:hover {
      transform: scale(1.1);
      color: @dark-blue;
    }
  }
  &__textSmall {
    color: @gray-background;
    font-size: 14px;
  }
}

.usersListForm {

  .usersListForm-form {
    .upload-btn {
      span > svg {
        color: @blue;
      }
      color: @blue;
    }
    .invalid-feel {
      position: absolute;
      color: #ff4d4f;
      top: 43px;
    }
    &__password-wrapper {
      position: relative;
    }
    &__status {}
    &__password {
      &--generate,
      &--eye-input {
        position: absolute;
        top: 57px;
        right: 18px;
        width: 20px;
        height: 20px;
        cursor: pointer;
        color: #000;
        span, svg {
          width: 20px;
          height: 20px;
        }
      }
      &--generate {
        right: -16px;
        top: 58px;
        width: 18px;
        height: 18px;
        span, svg {
          color: @blue;
          &:hover {
            color: @dark-blue;
          }
        }
      }
      margin-top: 15px;
    }
    &__noImg {
      cursor: pointer;
    }
    &__change-password {
      border-radius: 5px;
      max-width: 240px;
      width: 100%;
      margin-top: 48px;
      height: 44px;
      &--confirm {
        max-width: 200px;
        border-radius: 5px;
        padding: 0 11px;
        height: 22px !important;
      }

      &--noConfirm {
        max-width: 200px;
        border-radius: 5px;
        margin-left: 5px;
        padding: 0 11px;
        height: 22px!important;
        margin-top: 10px;
      }
    }
    .ant-row {
      margin-top: 10px;
    }
    .ant-checkbox-disabled .ant-checkbox-inner {
      background-color: @light-fill;
    }
    width: 97%;
    padding: 0 15px;
    &--picture {
      margin-top: 70px!important;
      .existingLabel__item--user {
        padding: 0;
      }
    }
    &__item {
      .label {
        display: flex;
        align-items: flex-end;
      }
      sup {
        display: inline-flex;
        align-self: center;
        color: @coral;
        font-size: 24px;
      }
      &--img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        object-fit: cover;
      }

      input {
        min-height: 45px;
      }
      .ant-select-selector {
        min-height: 45px;
      }
      .ant-radio-group {
        flex-direction: row;
      }
    }

    .img {
      display: block;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      object-fit: cover;
    }


    .label {
            color: @black;
            margin-top: 14px;
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
            &-right {
              text-align: right;
              padding-right: 15px;
            }
            &-picture {
              margin-top: 16px;
         }
          }
    &__buttons {
            margin-top: 30px;
            display: flex;
            justify-content: flex-end;
            &--cancel {
              color: @blue;
              &:hover {
                color:@dark-blue
              }
            }
            button {
              font-size: 12px;
              line-height: 12px;
              padding: 14px 34px;
              height: auto;
              margin-left: 15px;
              border-radius: 5px;
            }
          }
  }
}

@media (max-width: 800px) {
  .usersListForm {
    &__form {
      margin: 0 auto 50px;
    }
  }
}

@primary-color: #0061D9;