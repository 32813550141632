@import 'src/styles/vars';
.loader_container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: white;
	height: 50vh;
	:global(.ant-btn) {
		border-radius: 5px;
	}
}
.title {
	margin-bottom: 10px;
}
.doc_viewer {
	max-height: 700px;
	:global(#pdf-download) {
		display: none!important;
	}
}

.dynamic_ellipsis {
	display: flex;
	justify-content: space-between;
	margin-bottom: 30px;
}

@keyframes scaling {
	0%,
	100% {
		transform: scale(0.4);
		background-color: rgba(@blue, 0.4);
	}
	40% {
		transform: scale(1);
		background-color: rgba(@blue, 0.8);
	}
	50% {
		transform: scale(1);
		background-color: rgba(@blue, 1);
	}
}
.dot1,
.dot2,
.dot3 {
	width: 15px;
	height: 15px;
	border-radius: 50%;
	display: inline-block;
	margin: 0.3em;
	animation: scaling 1.5s ease-in-out infinite;
}
.dot1 {
	animation-delay: 0.2s;
}
.dot2 {
	animation-delay: 0.4s;
}
.dot3 {
	animation-delay: 0.6s;
}

@primary-color: #0061D9;