@import "src/styles/vars";
.files {
  width: 100%;
}
.uploader {
  width: 100%;
  display: flex;
  flex-direction: column;
  :global(.ant-upload) {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  :global(.ant-upload-list-item) {
    background-color: @light-fill;
    border-radius: 5px;
  }
}
.title {
  font-size: 16px;
}
.btn {
  color: @blue;
  &:hover {
    color: @dark-blue;
    background-color: transparent;
  }
}
@primary-color: #0061D9;