@import "src/styles/vars";

.link-cell {
  display: inline-block;
  width: 100%;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  &:hover {
    color: @dark-blue;
  }
}


@primary-color: #0061D9;