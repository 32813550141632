@import "src/styles/vars";

.name-center-cell {
  width: 100%;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  p {
    font-size: 12px;
    line-height: 12px;
  }
}


@primary-color: #0061D9;