@import "src/styles/vars";
.item {
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  padding: 4px 8px;
  color: @main;
  background-color: @gray-separator;
  cursor: pointer;
  :global(span) {
    max-width: 200px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:global(.active) {
    background-color: rgba(@blue, 0.2);
  }
}
.icon {
  color: @coral;
  margin-left: 5px;
}
@primary-color: #0061D9;