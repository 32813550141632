@import "src/styles/vars";
.table-ModalArchive {
  margin-top: 50px;
  .anticon {
    transition: 0.3s ease-in all;
  }
  &__delete {
    color: @coral;
    cursor: pointer;
    .anticon {
      font-size: 20px;
    }
    > * {
      pointer-events: none;
    }
    &:hover {
      .anticon {
        transform: scale(1.1);
      }
    }
  }
}
.ModalArchive {
  width:1392px!important;
  .ant-modal-title {
    color: @blue;
    font-size: 20px;
  }
}
.ModalArchive__content {
  .form-group {
    .ant-btn {
      height: 45px;
      margin-left: 5px;
      border-radius: 5px;
    }
    .ant-select-selector {
      border-radius: 5px;
    }
  }

  &__content {
    font-size: 18px;
    color: @text-color-light;
  }
  &__nav {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 30px;
    .form-group {
      margin-top: 0;
      margin-right: 20px;
      &--statuses,
      &--types,
      &--datePicker {
        width: 235px;
        .datePicker {
          width: 100%;
          height: 44px;
          border-radius: 5px !important;
        }
        .ant-select {
          width: 100% !important;
        }
      }
      &--datePicker {
        width: 280px;
      }
      .ant-select-selection-overflow {
        min-height: 39px;
        overflow: hidden;
      }
      .ant-select-multiple {
        height: auto;
      }
      .ant-select-selector {
        border-radius: 5px;
      }
      .ant-select-single {
        .ant-select-selector {
          height: 45px;
          display: flex;
          align-items: center;
        }
      }
      .ant-input {
        border-radius: 5px 0 0 5px;
        height: 45px;
      }
      .ant-input-search-button {
        border-radius: 0 5px 5px 0;
        height: 45px;
      }
    }
    .ant-dropdown-link {
      font-size: 16px;
    }
  }
}

@primary-color: #0061D9;