@import "src/styles/vars";

@heightPagination: 58px;

.gantt {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  margin-top: 50px;
  padding-bottom: @heightPagination;
  :global(>div:not(.pagination)) {
    overflow: hidden;
    width: 100%;
    @media (max-width: @lg) {
      overflow-x: scroll;
      :global(._CZjuD) {
        overflow: inherit;
      }
    }
  }

  :global(._2dZTy:nth-child(even)) {
    fill: #fff;
  }
}
.header {
  height: 70px;
  border: 1px solid #e0e0e0;
  border-right: none;
  font-size: 25px;
  line-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.details {
  max-width: 350px;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: @white;
  color: @main;
  fill: @main;
  padding: 10px;
  box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
  border-radius: 5px;
  :global(ul) {
    list-style: none;
    margin: 0;
    padding: 0;
  }

}
.pagination {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
}
@primary-color: #0061D9;