@import 'src/styles/vars';

.field {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 8px;
	background-color: #f1f6fd;
	input {
		border-radius: 3px;
	}
	.date_picker {
		width: 100%;
		border-radius: 3px;
	}
	&__name {
		user-select: none;
		flex-shrink: 0;
		font-weight: normal;
	}
	&__item {
		display: flex;
		gap: 10px;
		align-items: center;
		margin-bottom: 5px;
	}
	.counter {
		user-select: none;
		font-weight: normal;
		white-space: nowrap;
	}
	.add {
		display: flex;
		align-items: center;
		gap: 5px;
		font-size: 15px;
		cursor: pointer;
		svg {
			color: @blue;
			transition: 0.5s ease all;
			&:hover {
				transform: scale(1.05);
				color: @dark-blue;
			}
		}
		&:global(.disabled) {
			color: @gray-background;
			cursor: not-allowed;
			&:hover {
				color: @gray-background;
			}
		}
		&:hover {
			color: @blue;
		}
	}
	.remove {
		font-size: 15px;
		color: @coral;
		transition: 0.5s ease all;

		&:hover {
			transform: scale(1.05);
			color: @dark-coral;
		}
	}
}
.container {
	:global(.ant-collapse-content-box) {
		padding: 0 !important;
	}
	:global(.ant-collapse-item) {
		border: 0 !important;
		background-color: #f1f6fd;
	}
	.collapse {
		user-select: none;
		border-radius: 3px;
		font-weight: 500;
	}
	.panel {
		user-select: none;
	}
	.error {
		color: @dark-coral;
	}
}
.filters {
	&:global(.mb) {
		margin-bottom: 10px;
	}
}

.buttons {
	margin-top: 20px;
	display: flex;
	justify-content: flex-end;
	gap: 10px;
}

@primary-color: #0061D9;