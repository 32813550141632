@import "src/styles/vars";

.header {
  width: 100%;
  border-bottom: 1px solid #D5D1D7;
  padding: 20px 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__left {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    .mainMenu {
      margin-left: 20px;
      display: flex;
    }
    a > img {
      display: block;
      width: 60px;
      height: 60px;
      max-width: 60px;
      max-height: 60px;
      cursor: pointer;
    }
  }
  &__right {

  }
}
@media (max-width: 978px) {
  .header {
    &__left {
      .mainMenu {
        display: none;
      }
    }
  }
}
@media (max-width: 500px) {
  .header {
    padding: 10px;
    &__left {
      width: auto;

      a > img {
        display: block;
        width: 40px;
        height: 40px;
        max-width: 60px;
        max-height: 60px;
      }
    }
    &__right {
      width: 100%;
      .infoMenu {
        width: 100%;
        justify-content: space-between;
      }
    }
  }
}
@primary-color: #0061D9;