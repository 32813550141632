@import "src/styles/vars";

.tour {
    cursor: pointer;
    margin-right: 15px;
    font-size: 20px;
}

.icon_tour_header {
  cursor: pointer;
  font-size: 20px;
  color: @coral;
}

.icon_tour,
.icon {
  cursor: pointer;
  font-size: 16px;
  min-width: 25px;
}
.icon_tour {
  color: @coral;
}
.icon {
  color: @blue;
}
.popover {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
}
.text {
  color: @main;
  cursor: pointer;
  font-size: 15px;
}
@media (max-width: 400px) {
  .icon_tour_header {
    display: none;
  }
}
@primary-color: #0061D9;