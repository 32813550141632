@import "src/styles/vars";
.container {
  margin-top: 15px;
  width: 100%;
  background-color: #F1F6FD;
  color: @main;
  font-size: 14px;
  line-height: 20px;

  border-left: 3px solid @blue;
  padding: 15px;
  border-radius: 5px;

  display: flex;
  gap: 10px;

  .icon {
    color: @blue;
  }
  &:global(.danger) {
    border-left: 3px solid @coral;
    background-color: @light-coral;
    .icon {
      color: @coral
    }
  }
}
@primary-color: #0061D9;