@import 'src/styles/vars';

.container {
	height: auto;
	display: flex;
	align-items: center;
	position: relative;
	:global(.ant-upload-list) {
		height: auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		gap: 10px 5px;
	}

	:global(.ant-upload-list-picture-card-container) {
		width: 70px;
		height: 70px;
		position: relative;
		:global(.ant-image) {
			width: 65px;
			height: 65px;
			position: relative;
			display: flex;
			justify-content: center;
			:global(img) {
				display: block;
				object-fit: contain;
			}
		}
		:global(.anticon-delete) {
			width: 100%;
			color: @coral;
			cursor: pointer;
			position: relative;
			z-index: 3;
			&:hover {
				color: @dark-coral;
			}
		}
	}
	:global(.ant-image-mask-info) {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-size: 8px;
	}
	:global(.ant-upload.ant-upload-select-picture-card) {
		width: 70px;
		height: 70px;
	}
	:global(.anticon-eye) {
		color: @blue !important;
		&:hover {
			color: @dark-blue !important;
		}
	}
}
.limit {
	width: auto;
	min-width: 30px;
	height: 30px;
	border-radius: 50%;
	background-color: @gray-separator;
	color: @blue;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	line-height: 30px;
	cursor: pointer;
	transition: 0.3s ease all;
	:global(.anticon) {
		margin: 0;
	}
	&:hover {
		background-color: @gray-background;
	}
}
.error {
	width: 70px;
	height: 70px;
	position: relative;
	:global(.ant-empty-img-default) {
		width: 70px;
		height: 70px;
		z-index: 1;
	}
	:global(.ant-empty-description) {
		z-index: 2;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		font-size: 9px;
	}
}
.preview {
	display: flex;
	width: 100%;
	min-height: 500px;
	padding-bottom: 40px;
	.carusel {
		width: 60%;
		position: relative;

		:global(.slick-dots) {
			position: absolute;
			bottom: -25px;
		}
		:global(.slick-dots li) {
			width: 40px;
			height: 6px;
			border-radius: 5px;
			&:global(.slick-active) {
				width: 55px;
				height: 6px;
			}
		}

		:global(.slick-dots li button) {
			border-radius: 5px;
			height: 6px;
			opacity: 0.4;
			background-color: @blue;
			&:focus,
			&:hover,
			&:active {
				background-color: @blue;
				opacity: 0.6;
			}
		}
		:global(.slick-dots li.slick-active button) {
			background-color: @blue!important;
			opacity: 0.8;
			width: 55px;
		}
	}
	.carusel img {
		width: 100%;
		height: auto;
		display: block;
	}
	.info {
		padding: 10px;
		width: 40%;
	}
	.info_item {
		.h3 {
			color: @blue;
		}
		.title {
			font-weight: 500;
			margin: 0;
			padding: 0;
			font-size: 14px;
		}
		.text {
			font-size: 14px;
		}
	}
}
.modal_title {
	font-size: 20px;
}

@primary-color: #0061D9;