@import "src/styles/vars";

.root {
  padding: 20px;
 :global(.ant-select-selector) {
   border-radius: 5px!important;
 }
  :global(.ant-btn) {
    height: 39px;
    margin-left: 5px;
    border-radius: 5px;
 }
}

.confirm {
  background: rgba(134, 179, 233, 0.12);
  border-radius: 5px;
  padding: 15px;
  p {
    font-size: 14px;
  }
  .form-group {
    flex-direction: row;
    align-items: center;
    .label {
      font-size: 14px;
      color: @main;
      user-select: none;
    }
  }
}
.table {
  margin-top: 15px;
}
.bulk {
  width: 320px;
}
.select {
  width: 320px;
}

@primary-color: #0061D9;