@import 'src/styles/vars';

.root {
	padding: 20px;
}
.item {
	margin-bottom: 12px;
	padding: 15px;
	background-color: #f9fbfd;
}
.h3 {
	font-weight: 400;
	font-size: 12px;
	color: @dark-blue;
}
.block {
	padding: 10px 25px;
	display: flex;
	justify-content: space-between;
}
.text {
	padding-right: 10px;
	color: @main;
}
.time_line {
	color: @green;
}

@primary-color: #0061D9;