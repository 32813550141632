@import "src/styles/vars";
.modalInfo {
  display: flex;
  flex-wrap: wrap;
  &__item {
    margin-right: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    max-width: 90%;
    cursor: pointer;
    .title {
      font-size: 15px;
      line-height: 20px;
      margin-bottom: 15px;
    }
    .content {
      display: flex;
      align-items: center;
    }
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 5px;
      width: 53px;
      height: 49px;
      background-color: @green-yellow;
      border-radius: 7px;
      border: none;
      flex-shrink: 0;
      transition: 0.2s ease all;
      &:disabled {
        background-color: @gray-background;
      }
      .anticon {
        color: @main;
      }
      svg {
        width: 20px;
        height: 20px;
      }
    }
    .text {
      max-width: 157px;
      font-weight: 400;
      line-height: 17px;
      font-size: 12px;
      color: @gray-background;
    }
    &:hover {
      .icon {
        background-color: @green-yellow;
        transform: scale(1.1);
      }
    }
  }
}
@primary-color: #0061D9;