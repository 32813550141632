@import "src/styles/vars";
.statusTaskForm {
  width: 63px;
  background: @light-fill;
  padding: 8px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  &__item {
    margin-top: 35px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    span {
      color: @blue;
    }
    span, span > svg {
      width: 25px;
      height: 25px;
    }
    &:first-child {
      margin-top: 20px;
    }
    &:last-child {
      margin-bottom: 20px;
      span {
        color: @blue !important;
      }
    }
    &.active {
      span {
        color: @green;
      }
    }
    transition: 0.3s ease-in-out all;
    &:hover {
      transform: scale(1.2);
    }
  }
}
@media (max-width: 570px) {
  .statusTaskForm {
    margin-top: 30px;
    justify-content: space-around;
    flex-direction: row;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    &__item {
      margin-top: 0;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }

  }
}
@primary-color: #0061D9;