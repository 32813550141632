@import "src/styles/vars";
.card-movie {
  .card {
    &__bg {
      background: rgb(0,80,178);
      background: linear-gradient(180deg, rgba(0,80,178,0.7049413515406162) 0%, rgba(0,0,0,0.7) 15%, rgba(0,0,0,0) 50%, rgba(0,0,0,0.7) 85%, rgba(0,80,178,0.7) 100%);
    }

    &__img {
      position: absolute;
      overflow: hidden;
      width: 100%;
      height: 100%;
      border-radius: 20px;
      img {
        display: block;
        max-width: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.3s ease-in-out all;
        border-radius: 20px;
      }
    }
  }
  &:hover {
    .card__img img {
      transform: scale(1.1);
    }
  }
}
@primary-color: #0061D9;