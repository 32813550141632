.container {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: 15px 5px;
  grid-template-areas:
    "name_label name name  name . type_label type type"
    "requestNumber_label requestNumber requestNumber requestNumber . budget_label budget budget"
    ". . . . promotionalBudget_label promotionalBudget_label promotionalBudget promotionalBudget"
    "contractNumber_label contractNumber contractNumber contractDate_label contractDate agreementDate_label agreementDate agreementDate"
    "isPrivateProject_label isPrivateProject isPrivateProject igk_label igk advancePayment_label advancePayment . "
    "contest_label contest contest year_label year session_label session session"
    "goal_label goal goal goal goal goal goal goal"
    "description_label description description description description description description description"
    "status_label status status completionDate_label completionDate_label completionDate completionDate .";
  @media(max-width: 1356px) {
    grid-template-rows: auto auto auto auto auto auto 1fr 1fr auto;
    grid-template-areas:
    "name_label name name  name . type_label type type"
    "requestNumber_label requestNumber requestNumber requestNumber . budget_label budget budget"
    ". . . . promotionalBudget_label promotionalBudget_label promotionalBudget promotionalBudget"
    "contractNumber_label contractNumber contractNumber contractNumber contractDate_label contractDate contractDate contractDate"
    "agreementDate_label agreementDate agreementDate agreementDate isPrivateProject_label isPrivateProject isPrivateProject isPrivateProject"
    "igk_label igk igk . advancePayment_label advancePayment . ."
    "contest_label contest contest year_label year session_label session session"
    "goal_label goal goal goal goal goal goal goal"
    "description_label description description description description description description description"
    "status_label status status completionDate_label completionDate_label completionDate completionDate .";
  }
  @media(max-width: 900px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
    "name_label name name"
    "type_label type type"
    "requestNumber_label requestNumber requestNumber"
    "budget_label budget budget"
    "promotionalBudget_label promotionalBudget promotionalBudget"
    "contractNumber_label contractNumber contractNumber"
    "contractDate_label contractDate contractDate"
    "agreementDate_label agreementDate agreementDate"
    "isPrivateProject_label isPrivateProject isPrivateProject"
    "igk_label igk igk"
    "advancePayment_label advancePayment advancePayment"
    "contest_label contest contest"
    "year_label year year"
    "session_label session session"
    "goal_label goal goal"
    "description_label description description"
    "status_label status status"
    "completionDate_label completionDate completionDate";
  }
  @media(max-width: 600px) {
    grid-template-areas:

    "name_label"
    "name"
    "type_label"
    "type"
    "requestNumber_label"
    "requestNumber"
    "budget_label"
    "budget"
    "promotionalBudget_label"
    "promotionalBudget"
    "contractNumber_label"
    "contractNumber"
    "contractDate_label"
    "contractDate"
    "agreementDate_label"
    "agreementDate"
    "isPrivateProject_label"
    "isPrivateProject"
    "igk_label"
    "igk"
    "advancePayment_label"
    "advancePayment"
    "contest_label"
    "contest"
    "year_label"
    "year"
    "session_label"
    "session"
    "goal_label"
    "goal"
    "description_label"
    "description"
    "status_label"
    "status"
    "completionDate_label"
    "completionDate";
  }
}
.text_right {
  text-align: right;
  @media(max-width: 900px) {
    text-align: left;
  }
}


.name_label { grid-area: name_label; }
.name { grid-area: name; }
.type_label { grid-area: type_label; }
.type { grid-area: type; }

.requestNumber { grid-area: requestNumber; }
.requestNumber_label { grid-area: requestNumber_label; }
.budget { grid-area: budget; }
.budget_label { grid-area: budget_label; }

.promotionalBudget { grid-area: promotionalBudget; }
.promotionalBudget_label { grid-area: promotionalBudget_label; }

.contractNumber { grid-area: contractNumber; }
.contractNumber_label { grid-area: contractNumber_label; }

.contractDate { grid-area: contractDate; }
.contractDate_label { grid-area: contractDate_label; }
.agreementDate { grid-area: agreementDate; }
.agreementDate_label {
  grid-area: agreementDate_label;
  @media(max-width: 1356px) {
    &.text_right {
      text-align: left;
    }
  }

}
.isPrivateProject_label { grid-area: isPrivateProject_label; }
.isPrivateProject {
  grid-area: isPrivateProject;
  display: flex;
  align-items: center;
  width: 100%;
  :global(.form-group) {
    width: 100%;
    gap: 10px;
    flex-wrap: nowrap;
    flex-direction: row-reverse;
  }
  :global(.nativeLabel) {
    width: 93%;
    float: right;
  }
  @media (max-width: 738px) {
    :global(.form-group) {
      //flex-direction: row;
      //flex-wrap: nowrap;
    }
    :global(.nativeLabel) {
      //text-align: right;
    }
  }
  @media (max-width: 446px) {
    :global(.nativeLabel) {
      text-align: left;
    }
  }
}
.igk_label {
  grid-area: igk_label;
  @media(max-width: 1356px) {
    text-align: left;
  }
}
.igk { grid-area: igk; }
.advancePayment { grid-area: advancePayment; }
.advancePayment_label { grid-area: advancePayment_label;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 5px;
  :global(.form-group) {
    width: auto;
    margin-right: 10px;
  }
  @media(max-width: 900px) {
    justify-content: flex-start;
  }
}

.contest_label { grid-area: contest_label; }
.contest { grid-area: contest; }
.year_label { grid-area: year_label; }
.year { grid-area: year; }
.session_label { grid-area: session_label; }
.session { grid-area: session; }

.goal_label { grid-area: goal_label; }
.goal { grid-area: goal; }

.description_label { grid-area: description_label; }
.description { grid-area: description; }

.status_label { grid-area: status_label; }
.status { grid-area: status; }
.completionDate_label { grid-area: completionDate_label; }
.completionDate { grid-area: completionDate; }

@primary-color: #0061D9;