@import 'src/styles/vars';

.analyticsPage {
	padding: 0 0 30px 0;
	width: 100%;
	&__body {
		width: 100%;
	}
}

@media (max-width: 600px) {
	.analyticsPage {
		&__actions-basic {
			margin-left: 10px;
		}

		&__body {
			padding: 0 10px;
		}
		&__navigation {
			width: 100%;
		}
	}
}

@media (min-width: 600px) {
	.analyticsPage {
		height: 100%;
		&__actions-basic,
		&__actions-coverage,
		&__navigation {
			width: 100%;
		}
		&__actions-basic {
			max-width: 1800px;
			margin-top: 20px;
			padding-left: 40px;
		}
		&__actions-coverage {
			margin-top: 20px;
			padding-left: 40px;
		}
		&__body {
			margin-top: 20px;
			margin-bottom: 20px;
			padding-left: 40px;
			&-summary {
				padding-left: 0;
				&__checkbox {
					padding-left: 20px;
					span {
						color: @blue;
						&:hover {
							color: @dark-blue;
						}
					}
				}
			}
		}
	}
}

@primary-color: #0061D9;