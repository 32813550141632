@import "src/styles/vars";
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #E4E7EB;
  font-size: 18px;
  cursor: pointer;
  transition: 0.3s ease all;
  &:global(.isFavoriteProject) {
    color: rgba(@orange, 0.5);
  }
  &:hover {
    color: @orange;
  }
}
@primary-color: #0061D9;