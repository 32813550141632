@import "src/styles/vars";

.ContentPage__filters {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  .form-group {
    margin-top: 0;
    width: 250px;
  }
  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .form-group {
      input {
        min-height: 32px;
      }
      .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
        border-radius: 5px 0 0 5px;
      }
      .ant-input-affix-wrapper {
        border-radius: 5px 0 0 5px;
        height: 40px;
      }
      .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
        border-radius: 0 5px 5px 0;
      }
      .ant-input-search-button {
        height: 40px;
      }
    }
  }
  &-left {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .filters {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 5px;
      width: 40px;
      height: 40px;
      border-radius: 5px;
      color: @white;
      background-color: @green;
      cursor: pointer;
      transition: 0.3s ease all;
      &:hover {
        background-color: @green-yellow;
      }
    }
    .count-content {
      color: @gray-background;
      margin-right: 15px;
      margin-left: 15px;
      white-space: nowrap;
      @media (max-width: 500px) {
        margin-top: 15px;
      }
    }
    .clear-filter {
      margin-right: 15px;
      margin-left: 15px;
      height: 40px;
      @media (max-width: 500px) {
        margin-top: 15px;
      }
      .ant-btn {
        height: 100%;
        border-radius: 5px;
        color: @blue;
        border-color: @blue;
        &:hover {
          color: @dark-blue;
          border-color: @dark-blue;
        }
      }
    }
  }
  &-right {
    margin-right: 15px;
  }
  &-sort {
    display: flex;
    margin-bottom: 15px;
    margin-left: 15px;
    .label {
      padding: 0 8px 0 8px;
      border: 1px solid #d9d9d9;
      border-radius: 5px 0 0 5px;
      border-right: none;
      display: flex;
      align-items: center;
      color: @gray-separator;
      font-weight: 400;
      font-size: 11px;
      cursor: default;
    }
    .form-group {
      @media (max-width: 400px) {
        width: 180px;
      }
      .ant-select {
        height: 40px;

        .ant-select-selector {
          height: 40px;
          border-radius: 0 5px 5px 0;
        }
      }
      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
        height: 40px;
      }
      &.clear {
        .ant-select {
          .ant-select-selector {
            height: 40px;
            border-radius: 0;
          }
        }
      }
    }
    .order {
      height: 40px;
      width: 40px;
      font-size: 20px;
      background-color: @green;
      transition: 0.3s ease all;
      color: @white;
      border-radius: 0 5px 5px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:hover {
        background-color: @green-yellow;
      }
    }
  }

  &-items {
    display: flex;
    flex-wrap: wrap;

    .item {
      display: flex;
      align-items: center;
      margin-right: 30px;
      cursor: pointer;
      color: @blue;
      transition: 0.3s ease all;

      &.active {
        color: @main;
      }

      .count {
        margin-left: 5px;
        padding: 3px 5px;
        background: @green-yellow;
        border-radius: 50%;
        height: 30px;
        min-width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          color: @main;
          font-size: 12px;
          line-height: 12px;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        color: @green;
      }
    }
  }
  &-btn {
    height: 45px;
    border-radius: 5px;
  }
}

@primary-color: #0061D9;