@import 'src/styles/vars';

.TabItem__list {
	&-item {
		background: rgba(196, 196, 196, 0.28);
		border-radius: 7px;
		width: 210px;
		padding: 10px;
		display: flex;
		flex-direction: column;
		margin-right: 10px;
		margin-bottom: 10px;
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
		transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
		cursor: pointer;
		&.active {
			transform: scale(1.02);
			box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
		}
		.fileOutlined {
			height: 80%;
			display: flex;
			justify-content: center;
			align-items: center;
			svg {
				font-size: 25px;
				color: @blue;
			}
		}
		small {
			color: @blue;
			cursor: pointer;
		}
		.title {
			font-size: 14px;
			min-height: 50px;
		}

		.img {
			height: 108px;
			img {
				display: block;
				max-width: 100%;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.footer {
			color: rgba(0, 0, 0, 0.35);
			display: flex;
			justify-content: space-between;
			font-size: 12px;

			.green {
				color: #0cbc8b;
			}

			.red {
				color: #ff2727;
			}
		}
	}
}

@primary-color: #0061D9;