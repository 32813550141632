@import "src/styles/vars";
.modal-team {
  &__list {
    display: flex;
    flex-direction: column;
    .item {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin-bottom: 15px;
      &__header {
        display: flex;
        align-items: center;
        img {
          display: block;
          width: 50px;
          min-width: 50px;
          height: 50px;
          margin-right: 10px;
          border-radius: 50%;
          overflow: hidden;
          object-fit: cover;
        }
        span {
          font-size: 12px;
          color: @dark-blue;
        }
      }
      &__leader {
        font-size: 13px;
        color: @green;
      }
      &__content {
        width: 100%;
        margin-top: 15px;
        &__rhombus {
          display: block;
          width: 8px;
          height: 8px;
          transform: rotate(45deg);
          margin-right: 5px;
          background-color: @white;
          &.ACTIVE {
            background-color: @green;
          }
          &.ARCHIVE {
            background-color: @orange;
          }
          &.BLOCKED {
            background-color: @gray-background;
          }
        }
        &__name-wrapper {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        &__name {
          flex-grow: 1;
        }
        &__star {
          font-size: 18px;
          margin-right: 50px;
           .anticon {
            margin-right: 0!important;
          }
          svg {
            margin-left: 10px;
            color: @green-yellow;
          }
        }
        ul {
          margin: 10px 0 0;
          padding: 0;
          list-style: none;
          font-size: 12px;
          li {
            line-height: 15.6px;
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
            min-width: 300px;
            .item__content__amount {
              display: flex;
              margin-right: 50px;

              align-items: center;
              justify-content: center;
              color: #0061D9;
              border: 1px solid #F0F0F0;
              background-color: #F0F0F0;
              width: 18px;
              height: 18px;
              font-size: 9px;
              border-radius: 50%;
              flex-shrink: 0;
              cursor: pointer;
            }
            .anticon {
              color: @dark-blue;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}

@primary-color: #0061D9;