@import "src/styles/vars";
.main {
  display: flex;
  justify-content: center;
}

.icon_disabled {
  color: @gray-separator;
  margin-right: 5px
}
.icon {
  color: @blue;
  margin-right: 5px
}

.text {
  color: @gray-separator
}
@primary-color: #0061D9;