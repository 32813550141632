@import "src/styles/vars";
.container {
  padding: 15px;
}
.title {
  color: @main;
  font-size: 25px;
  line-height: 30px;
}

@primary-color: #0061D9;