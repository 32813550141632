@import 'src/styles/vars';

.field-organization {
	width: 100%;
	padding: 10px;
	margin-top: 20px;
	margin-bottom: 20px;
	background: rgba(240, 246, 252, 0.5);
	border-radius: 5px;
	-webkit-box-shadow: 0 0 75px 5px rgba(0,0,0,0.14);
	-moz-box-shadow: 0 0 75px 5px rgba(0,0,0,0.14);
	box-shadow: 0 0 75px 5px rgba(0,0,0,0.14);
	&__tags {
		display: flex;
		flex-wrap: wrap;
		margin-left: 20px;
		align-items: center;
	}
	&__invalid-feel {
		position: absolute;
		color: #ff4d4f;
		top: 23px;
	}
	&__tooltip {
		p {
			font-size: 12px;
			color: white;
		}
		.ant-tooltip-open {
			width: 400px;
		}
	}
	p {
		display: flex;
		align-items: flex-end;
		flex-wrap: nowrap;
		margin: 0;
		padding: 0;
	}
	&__name {
		display: flex;
		justify-content: space-between;
		p {
			font-size: 16px;
			font-weight: 400;
		}
		span {
			font-size: 18px;
		}
		svg {
			color: #0061d9;
		}
		.field-organization__delete {
			svg {
				color: @coral;
			}
		}
	}
	&__phone,
	&__email {
		position: relative;
		margin-bottom: 40px;
		&--wrapper {
			display: flex;
			align-items: center;
			flex-wrap: nowrap;
			p {
				font-size: 16px;
				font-weight: 400;
			}
		}
	}
	&__organization {
		margin-bottom: 40px;
	}

	&__btn {
	}
	&__btn-wrapper {
		width: 100%;
		span {
			font-size: 18px;
		}
	}
}

@primary-color: #0061D9;