@import 'src/styles/vars';

.modal-projectInfo {
  width: 900px!important;
  .ant-collapse {
    background-color: white;
    border-color: white;
  }
  .content {
    padding: 10px;
    display: flex;
    flex-direction: column;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    color: @blue;
  }
  .ant-collapse-content {
    border-top: 1px solid transparent;
  }
  .ant-collapse > .ant-collapse-item {
    border-bottom: 1px solid @light-fill;
  }
  .item {
    .title {
      color: @blue;
      margin-bottom: 15px;
      margin-top: 15px;
      font-size: 12px;
      line-height: 15px;
    }
    &:first-child .title {
      margin-top: 0;
    }
    .list {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;
      li {
        background-color: @light-fill;
        color: @gray;
        margin-right: 15px;
        padding: 4px 7px;
        border-radius: 2px;
        font-size: 11px;
        margin-bottom: 8px;
        .anticon {
          color: @green;
        }
      }
    }
  }
}

@primary-color: #0061D9;