@import "src/styles/vars";

.SearchProjectContent {
  width: 80% !important;
  max-width: 1200px;

  @media (max-width: 1200px) {
    max-width: 900px;
  }

  @media (max-width: 700px) {
    width: 90% !important;
  }
  .content {
    &__UserFilterName {
      padding: 0 15px;
    }
    .clear-filter {
      margin-right: 15px;
      margin-left: 15px;
      height: 100%;
      .ant-btn {
        height: 100%;
        border-radius: 5px;
        color: @blue;
        border-color: @blue;
        &:hover {
          color: @dark-blue;
          border-color: @dark-blue;
        }
      }
    }
  }
  .ant-modal-body {
    padding: 0;
  }

  .checkbox {
    .ant-checkbox + span {
      color: @gray-background;
      font-size: 14px;
      user-select: none;
    }
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 35px;

    .title {
      font-size: 25px;
      line-height: 27px;
      color: @gray;
    }
  }

  .btn {
    margin-top: 15px;
  }

  .bottom {
    display: inline-block;
  }

  .items {
    .ant-collapse {
      border: none;
    }

    .ant-collapse-extra {
      .anticon {
        margin-right: 20px;
        color: @coral;
      }
    }

    .ant-collapse > .ant-collapse-item {
      background: rgba(134, 179, 233, 0.12);
      border-bottom: none;
      font-size: 14px;
      line-height: 27px;
    }

    .ant-collapse-content {
      border-top: none;
    }
  }

  .item-wrapper {
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    &.column {
      flex-direction: column;
    }
    @media (max-width: 1000px) {
      flex-wrap: nowrap;
      flex-direction: column;
    }

    .item {
      display: flex;
      align-items: center;
      margin-top: 15px;
      width: 50%;
      @media (max-width: 1000px) {
        width: 100%;
      }
      @media (max-width: 680px) {
        flex-direction: column;
      }
      &__checkbox {
        padding: 10px 15px;
        border-bottom: 1px solid rgba(@black, 0.2);

        .ant-checkbox + span {
          font-size: 12px !important;
        }
      }
      &__remove {
        color: @coral;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 18px;
        transition: 0.3s ease all;

        &:hover {
          color: @dark-coral;
        }
      }

      .label {
        margin-right: 15px;
        text-align: right;
        padding-left: 10px;
        width: 200px;
        font-size: 14px;
        line-height: 27px;
        color: @gray-background;
        @media (max-width: 680px) {
          text-align: left;
          padding-left: 0;
          width: 100%;
          margin-right: 0;
        }
      }
      .form-group {
        margin-top: 0;
        width: 100%;
        .ant-picker-range {
          max-height: 39px;
        }
        &.double {
          flex-direction: row;
        }
      }
    }
    &.column {
      .item {
        width: 100%;
        .label {
          width: 350px;
          @media (max-width: 680px) {
            text-align: left;
            padding-left: 0;
            width: 100%;
            margin-right: 0;
          }
        }
      }
    }
    &__group {
      display: flex;
      width: 100%;
      margin-top: 15px;
    }

    &__items {
      width: calc(100% - 40px);
      display: flex;
      gap: 10px;

      @media (max-width: 800px) {
        flex-wrap: wrap;
        margin-bottom: 20px;
      }

      .item {
        margin-top: 0;

        .ant-select-selector, .ant-select-selection-overflow {
          height: 42px !important;
        }
      }

      .or {
        width: 50px;
        margin-left: 10px;
        margin-right: 15px;

        span.ant-radio + * {
          color: @gray-separator;
        }

        @media (max-width: 800px) {
          .ant-radio-group .ant-radio-group-outline {
            flex-direction: row;
          }
        }
      }
    }
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@primary-color: #0061D9;