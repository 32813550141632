@import "src/styles/vars";
@gray: rgba(0, 0, 0, 0.38);
.notificationSidebar {
  .ant-drawer-body {
    padding: 0;
    h2 {
      color: @blue;
    }
  }
  &__container {
    display: flex;
    flex-direction: column;
  }
  &__nav {
    display: flex;
    flex-direction: column;
    &-switch {
      display: flex;
      justify-content: flex-end;
      color: @gray;
      .ant-switch {
        margin-left: 10px;
      }
    }
    &-info {
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
      color: @gray;
      cursor: pointer;
      transition: 0.3s ease all;
      &:hover {
        color: @blue;
      }
    }
  }
  &__header {
    padding: 15px;
  }

  &__list {
    height: calc(100vh - 226px);
    overflow: auto;
    padding: 0 15px;
    &-item {
      padding: 10px;
      margin-top: 15px;
      background: rgba(193, 216, 237, 0.04);
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

      &.noRead {
        background: #EEF4FA;
      }

      .notificationHeader {
        display: flex;
        justify-content: space-between;

        &__title {
          color: #69C9D0;
          font-size: 12px;
          line-height: 14px;
          font-weight: 700;
        }

        &__time {
          color: rgba(@blue, 0.54);
          font-size: 12px;
          line-height: 15px;
          font-weight: 400;
          white-space: nowrap;
        }
      }
      .notificationBody {
        margin-top: 10px;
        font-size: 11px;
        line-height: 13px;
      }
      .notificationFooter {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #b9b7bb;

        &__status {
          &-item {
            transition: all 0.3s ease-in;
            cursor: pointer;

            div {
              span {
                color: #69C9D0;
              }
            }

            &:hover {
              color: @text-color-light;

              div span {
                text-decoration: underline;
              }
            }
          }
        }

        &__btn {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background: rgba(158, 158, 158, 0.25);
          border: 1px solid rgba(153, 153, 153, 0.5);
          cursor: pointer;
          position: relative;
          overflow: hidden;
          &:after {
            content: '';
            width: 8px;
            height: 8px;
            position: absolute;
            top: 30%;
            left: 50%;
            border-radius: 50%;
            transform: translate(-50%);
            background: #FF2727;
            opacity: 0;
            transition: 0.3s ease-in-out all;
          }
          &.active {
            &:after {
              opacity: 1;
            }
            &:hover {
              &:after {
                opacity: 0;
              }
            }
          }
          &:hover {
            &:after {
              opacity: 1;
            }
          }
        }
      }
    }
  }
  &__line {
    height: 20px;
    background-color: rgba(@blue, 0.1);
  }
}
@primary-color: #0061D9;