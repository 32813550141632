@import 'src/styles/vars';

.modal {
  min-width: 500px;
  width: 650px !important;
  :global(.ant-modal-title) {
    font-weight: 500;
    font-size: 23px;
    color: @blue;
  }
  :global(.ant-radio-group) {
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 2fr 1.5fr;
    grid-template-rows: 1fr;
    grid-template-areas: '. . .';
  }
  :global(.ant-select ) {
    width: 100%;
    border-radius: 5px;
  }
  :global(.ant-select-selector) {
    min-height: 40px;
    border-radius: 5px!important;
  }
}
.wrapper {
  display: flex;
  gap: 5px;
  margin-bottom: 10px;
}
.text {
  flex-shrink: 0;
  width: 160px;
  max-width: 170px;
  padding: 0;
  margin: 0;
  font-size: 18px;
  color: @black;
}
.select_checkbox {
  margin-left: 7px;
}
.format {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@primary-color: #0061D9;