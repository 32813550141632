@import 'src/styles/vars';
.container {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: auto;
  grid-template-rows: auto;
  gap: 15px 5px;
  grid-template-areas:
    "isNationalProject_label isNationalProject_label isNationalProject isNationalProject"
    "timesLines_label timesLines_label timesLines timesLines"
    "firstNationalLevelTags_label firstNationalLevelTags_label firstNationalLevelTags secondNationalLevelTags"
    "category_label category_label category category"
    "genre_label genre_label genre genre"
    "licenseDate_label licenseDate_label licenseDate ."
    "targetAudienceAgeFrom_label targetAudienceAgeFrom_label targetAudienceAgeFrom targetAudienceAgeTo"
    "famousPeople_label famousPeople_label famousPeople famousPeople"
    "contentLocationRegions_label contentLocationRegions_label contentLocationRegions contentLocationRegions"
    "filmClusters_label filmClusters_label filmClusters filmClusters"
    "awards_label awards_label awards awards"
    "projectDataCheckedDatetime_label projectDataCheckedDatetime_label projectDataCheckedDatetime projectDataCheckedDatetime";
  @media(max-width: 980px) {
    grid-template-areas:
    "isNationalProject_label isNationalProject isNationalProject isNationalProject"
    "timesLines_label timesLines timesLines timesLines"
    "firstNationalLevelTags_label firstNationalLevelTags firstNationalLevelTags firstNationalLevelTags"
    "firstNationalLevelTags_label secondNationalLevelTags secondNationalLevelTags secondNationalLevelTags"
    "category_label category_label category category"
    "genre_label genre_label genre genre"
    "licenseDate_label licenseDate licenseDate licenseDate"
    "targetAudienceAgeFrom_label targetAudienceAgeFrom targetAudienceAgeFrom targetAudienceAgeFrom"
    "targetAudienceAgeFrom_label targetAudienceAgeTo targetAudienceAgeTo targetAudienceAgeTo"
    "famousPeople_label famousPeople famousPeople famousPeople"
    "contentLocationRegions_label contentLocationRegions contentLocationRegions contentLocationRegions"
    "filmClusters_label filmClusters filmClusters filmClusters"
    "awards_label awards awards awards"
    "projectDataCheckedDatetime_label projectDataCheckedDatetime projectDataCheckedDatetime projectDataCheckedDatetime";
  }
  @media(max-width: 700px) {
    grid-template-areas:
    "isNationalProject_label"
    "isNationalProject"
    "timesLines_label"
    "timesLines"
    "firstNationalLevelTags_label"
    "firstNationalLevelTags"
    "secondNationalLevelTags"
    "category_label"
    "category"
    "genre_label"
    "genre"
    "licenseDate_label"
    "licenseDate"
    "targetAudienceAgeFrom_label"
    "targetAudienceAgeFrom"
    "targetAudienceAgeTo"
    "famousPeople_label"
    "famousPeople"
    "contentLocationRegions_label"
    "contentLocationRegions"
    "filmClusters_label"
    "filmClusters"
    "awards_label"
    "awards"
    "projectDataCheckedDatetime_label"
    "projectDataCheckedDatetime";
  }
  @media(max-width: 570px) {
    padding-top: 30px;
  }
  :global {
    .ant-btn-link:hover, .ant-btn-link:focus {
      border-color: transparent;
    }
  }
}
.text_right {
  text-align: right;
  @media(max-width: 900px) {
    text-align: left;
  }
}

.checkboxInSelect {
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 5px;
  width: 100%;
  font-size: 14px;
  border-bottom: 1px solid rgba(@gray, 0.3);
}
.checkboxText {
  font-size: 12px;
}
.btnAdd {
  width: 100%;
}
.labelSmall {
  font-size: 14px;
}
.delete {
  color: @coral;
  height: 40px;
  font-size: 16px;
  &:hover {
    color: @dark-coral;
  }
}

.isNationalProject_label { grid-area: isNationalProject_label; }
.isNationalProject { grid-area: isNationalProject; }

.timesLines_label { grid-area: timesLines_label; }
.timesLines {
  grid-area: timesLines;
  :global(.ant-select-clear) {
    right: 24px;
  }
}

.firstNationalLevelTags_label { grid-area: firstNationalLevelTags_label; }
.firstNationalLevelTags { grid-area: firstNationalLevelTags; }
.secondNationalLevelTags { grid-area: secondNationalLevelTags; }
.filmClusters { grid-area: filmClusters; }

.targetAudienceAgeFrom {
  grid-area: targetAudienceAgeFrom;
  display: flex;
  gap: 8px;
  align-items: center;
}
.targetAudienceAgeTo {
  grid-area: targetAudienceAgeTo;
  display: flex;
  gap: 8px;
  align-items: center;
}
.targetAudienceAgeFrom_label { grid-area: targetAudienceAgeFrom_label; }

.licenseDate_label { grid-area: licenseDate_label; }
.licenseDate { grid-area: licenseDate; }

.category_label { grid-area: category_label; }
.category { grid-area: category; }

.genre_label { grid-area: genre_label; }
.genre { grid-area: genre; }

.famousPeople_label { grid-area: famousPeople_label; }
.famousPeople { grid-area: famousPeople; }

.filmClusters_label { grid-area: filmClusters_label; }
.filmClusters { grid-area: filmClusters; }

.contentLocationRegions_label { grid-area: contentLocationRegions_label; }
.contentLocationRegions { grid-area: contentLocationRegions; }

.awards_label { grid-area: awards_label; }
.awards {
  grid-area: awards;
  :global(.ant-select) {
    width: 100%;
  }
}

.projectDataCheckedDatetime_label { grid-area: projectDataCheckedDatetime_label; }
.projectDataCheckedDatetime { grid-area: projectDataCheckedDatetime; }


.timeline_wrapper {
  margin-bottom: 8px;
  :global(.ant-select-selector) {
    max-height: 39px!important;
  }
}
.active {
  color: @blue;
}
.disabled {
  color: @disabled;
}
.select {
  margin-top: -2px;
}

@primary-color: #0061D9;