@import "src/styles/vars";
.RecordsActions {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
  .item {
    cursor: pointer;
    color: @blue;
    > * {
      pointer-events: none;
    }
    &:hover {
      .anticon {
        transform: scale(1.1);
      }
    }
    .anticon {
      font-size: 20px;
      transition: 0.3s ease-in all;
    }
    &.save {
      color: @green;
    }
    &.cancel,
    &.delete {
      color: @coral;
    }
    &.send {
      color: @orange;
    }
    &.disabled {
      cursor: not-allowed;
      color: @disabled !important;
      &:hover {
        cursor: no-drop;
      }
    }
    &.error {
      color: @coral !important;
      &:hover {
        cursor: no-drop;
      }
    }
  }
}
@primary-color: #0061D9;