@import "src/styles/vars";
.modal_task {
  width: 1000px !important;
  :global(.ant-modal-title) {
    font-weight: 700;
    font-size: 18px;
    color: @green-yellow;
  }
  :global(.ant-modal-footer) {
    .ant-btn {
      height: 35px;
      span {
        font-size: 14px;
        line-height: 14px;
      }
    }
  }
}

@primary-color: #0061D9;