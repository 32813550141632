@import "src/styles/vars";
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 15px;
  gap: 10px;
  .empty {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .item {
    display: flex;
    width: 100%;
    padding: 4px 8px;
    border-radius: 3px;
    background-color: @light-fill;
  }
}
@primary-color: #0061D9;