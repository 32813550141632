@import "src/styles/vars";
.cell {
  font-size: 12px;
  white-space: nowrap;
  text-align: center;
  color: @main;
}
.list {
  cursor: pointer;
  color: @blue;
  &:hover {
    color: @dark-blue;
  }
}
.ul {
  padding: 30px;
  font-size: 14px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.li {
  background-color: @gray-separator;
  color: @main;
  padding: 5px 15px;
  border-radius: 5px;
}

@primary-color: #0061D9;