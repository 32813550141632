@import 'src/styles/vars';

.modal-user-profile {
	width: 960px !important;
	hr {
		border: 0.5px solid #bfbfbf;
	}
	.ant-modal-title {
		margin-left: 40px;
		font-weight: 400;
		font-size: 22px;
		color: darkblue;
	}
	&__tooltip {
		p {
			font-size: 12px;
			color: white;
		}
	}
	&__body {
		padding-left: 40px;
		padding-right: 40px;
		&__form {
			.form {
				&__text {
					margin-top: 20px;
					margin-left: 20px;
					margin-bottom: 20px;
					font-size: 20px;
					color: @dark-blue;
					span {
						margin-left: 10px;
					}
				}
				&__info {
					width: 100%;
					display: flex;
					align-items: center;
					margin-left: 20px;
					&__user-info {
						width: 100%;
						margin-left: 80px;
						.user-info {
							&__lastName,
							&__firstName,
							&__middleName,
							&__login,
							&__role {
								display: flex;
								justify-content: space-between;
								align-items: center;
								width: 100%;
								max-width: 350px;
								margin-bottom: 20px;
								.form-group {
									margin-top: 0;
								}
								p {
									margin: 0;
									padding: 0;
								}
							}
						}
					}
					.form-group {
						.upload-btn svg {
							color: @black;
						}
						.ant-upload-list-item-name {
							display: none;
						}
						.ant-upload-list-item-thumbnail {
							border-radius: 50%;
							img {
								border-radius: 50%;
							}
						}
						.ant-upload-list-item {
							border: none !important;
						}
						.upload-component {
							width: 100%;
						}
						.ant-input {
							margin-top: 0;
							min-height: 20px !important;
							transition: 0.3s ease-in all;
						}
						input {
							border: none;
							color: @black;
							padding: 0;
							margin: 0;
							font-weight: 400;
							font-size: 17px;
							-webkit-box-shadow: inset 0 0 0 70px @white;
							-webkit-text-fill-color: @gray-background;
							cursor: pointer;
						}
					}
				}
				&__avatar-wrapper {
					display: flex;
					max-width: 320px;
					min-width: 230px;
					align-items: flex-end;
				}
				&__avatar {
					width: 150px;
					height: 150px;
					border-radius: 50%;
					background: #f8f8f8;
					border: 2px solid @black;

					display: flex;
					justify-content: center;
					align-items: center;

					.img {
						display: block;
						border-radius: 50%;
						width: 150px;
						height: 150px;
						object-fit: cover;
					}
					.noImg {
						font-size: 44px;
						line-height: 115px;
						text-transform: uppercase;
						color: @green-yellow;
					}
				}
				&__avatar {
					position: relative;
					.anticon {
						position: absolute;
						font-size: 20px;
						cursor: pointer;
					}
					.anticon-close {
						top: -5px;
						right: -5px;
					}
					.anticon-upload,
					.anticon-redo {
						bottom: -5px;
						right: -5px;
					}
					.ant-upload-list {
						display: none;
					}
				}
				&__footer {
					width: 100%;
					&__list {
						width: 100%;
						display: flex;
						margin-top: 40px;
						margin-bottom: 40px;
						justify-content: space-around;
						align-items: center;
						&__item {
							display: flex;
							align-items: center;
							transition: 0.3s ease-in-out all;
							div {
								display: flex;
								align-items: center;
								transition: 0.3s ease-in-out all;
								color: @main;
							}
							span {
								svg {
									color: @blue;
								}
							}
							a {
								display: flex;
								align-items: center;
								color: @main;
								span {
									svg {
										color: @blue;
									}
								}
							}
							span {
								font-weight: 400;
								font-size: 15px;
							}
							.anticon {
								font-size: 28px;
								margin-right: 7px;
							}
							&:hover {
								color: rgba(@dark-blue, 1);
								transform: scale(1.03);

								a {
									color: rgba(@dark-blue, 1);
								}
							}
						}
					}
					&__buttons {
						margin-top: 30px;
						display: flex;
						justify-content: flex-end;
						&--cancel {
							color: @blue;
							&:hover {
								color:@dark-blue
							}
						}
						button {
							font-size: 12px;
							line-height: 12px;
							padding: 14px 34px;
							height: auto;
							margin-left: 15px;
							border-radius: 5px;
						}
					}
					&__confirm {
						padding-left: 45px;
						padding-top: 20px;
						padding-right: 15px;
						padding-bottom: 15px;
						min-height: 150px;
						background-color: #F1F6FD;
						border-top-right-radius: 5px;
						border-bottom-right-radius: 5px;
						border-left: 4px solid @dark-blue;
						p {
							font-size: 14px;
							span {
								margin-left: -10px;
								color: @dark-blue;
								span {
									svg {
										margin-right: 5px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@primary-color: #0061D9;