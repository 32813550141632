@import "src/styles/vars";
.cookie {
  position: fixed;
  display: flex;
  justify-content: center;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  background-color: @light-fill;
  padding: 15px;
  font-size: 14px;
  p {
    color: @dark-blue;
  }
  &.login {
    position: relative;
  }
  &.yes {
    display: none;
  }
  p {
    margin-bottom: 0;
    max-width: 1200px;
  }
  .btn {
    background-color: @blue;
    color: @white;
    margin-left: 50px;
  }
}
@primary-color: #0061D9;