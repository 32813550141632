@import "src/styles/vars";

.modal-user {
  width: 90%!important;
  margin: 0 auto;
  .ant-modal-content {
    width: 80%!important;
    margin: 0 auto;
  }
  .ant-modal-title {
    font-weight: 400;
    font-size: 22px;
    color: @main;
  }
}
@primary-color: #0061D9;