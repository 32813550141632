@import "src/styles/vars";

.LinkPropsCell {
  display: inline-block;
  margin-top: 5px;
  width: 100%;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  cursor: pointer;
  &:hover {
    color: @dark-blue;
  }
}


@primary-color: #0061D9;