@import "src/styles/vars";
.modal-confirm-delete {
  .ant-modal-title {
    font-weight: 700;
    font-size: 18px;
    color: @blue;
  }
  &__content {
    display: flex;
    flex-direction: column;
    padding: 10px;
    span, strong {
      display: block;
      font-size: 18px;
      line-height: 24px;
    }
    span {
      color: @text-color-dark;
    }
    strong {
      margin-top: 15px;
      color: @blue;
    }
    input {
      margin-top: 15px;
      height: 40px;
    }
  }
  .ant-modal-footer {
    .ant-btn {
      height: 35px;
      span {
        font-size: 14px;
        line-height: 14px;
      }
    }
  }
}

@primary-color: #0061D9;