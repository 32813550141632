@import 'src/styles/vars';
.container {
	padding: 15px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	:global(hr) {
		border: 0;
		background-color: #f0f0f0;
		height: 1px;
		width: 100%;
	}
}
.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 20px;
	.title {
		font-size: 20px;
		line-height: 24px;
		color: @blue;
	}
}
.picker {
	width: 100%;
	display: flex;
	align-items: center;
	flex-wrap: nowrap;
}
.radio {
	min-width: 40%;
	margin-left: 10px;
}
.item {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 10px;
	margin-bottom: 10px;
	:global(.form-group) {
		margin-top: 0;
		width: 100%;
		font-size: 12px;
	}
	:global(.form-group label) {
		font-size: 12px;
	}
	:global(.ant-picker) {
		height: 39px;
		width: 100%;
	}
	:global(.ant-input) {
		min-height: 39px;
		width: 100%;
	}
	:global(.ant-radio-group) {
		flex-direction: row;
	}
	.label {
		width: 45%;
		font-size: 16px;
		line-height: 18px;
		:global(sup) {
			color: @coral;
			font-size: 14px;
		}
	}
	.option_label {
		margin-right: 5px;
	}
	.field {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 8px;
		&__item {
			display: flex;
			gap: 10px;
			align-items: center;
			margin-bottom: 5px;
		}
	}
}
.buttons {
	margin-top: 20px;
	display: flex;
	justify-content: flex-end;
	gap: 10px;
}
.title_banner {
	display: block;
	font-size: 14px;
	color: @blue;
}
.message_banner {
	display: block;
	font-size: 14px;
}
.error {
	color: @coral;
}
.radio_buttons {
	display: flex;
}
.dropdown {
	margin-top: 5px;
	margin-left: 10px;
	//margin-bottom: 10px;
}
@media (max-width: 700px) {
	.radio_buttons {
		flex-wrap: wrap;
	}
}
.option {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@primary-color: #0061D9;