@import "../../../styles/vars";

.resource-cell {
  width: 100%;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
}


@primary-color: #0061D9;