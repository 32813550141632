@import "src/styles/vars";
.form-group {
  margin-top: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  color: #fff;
  -webkit-tap-highlight-color: transparent;
  .nativeLabel {
    cursor: pointer;
  }
  &--radio {
    background-color:  rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    border: 1px solid #d9d9d9;
    overflow: hidden;
    .ant-radio-group {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    .ant-radio + span {
      color: @gray-background;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
    }
    .ant-radio-wrapper {
      width: 120px;
      margin: 0;
      min-height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      &.ant-radio-wrapper-checked {
        background-color: @white;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15),
          0 -4px 4px rgba(0, 0, 0, 0.05);
        .ant-radio + span {
          color: @main;
        }
      }
    }
    .ant-radio-wrapper.ant-radio-wrapper-checked {
      border-radius: 6px;
    }
    .ant-radio {
      display: none;
    }

  }
  .select-wrapper, .quill-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    &.error {
      .ql-toolbar.ql-snow,
      .ql-container.ql-snow {
        border-color: @accent-red !important;
      }

    }
  }

  .error {
    border-color: @accent-red !important;
    > .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-color: @accent-red !important;
    }
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: @green;
  }
  .ant-radio-inner::after {
    background-color: @green;
  }
  .ant-radio-checked::after {
    border: 1px solid @green;
  }
  &__quill {
    .ql-editor {
      min-height: 100px;
      color: @accent-color;
    }
    .quill {
      width: 100%;
    }
    .limit {
      color: @blue;
      &.error {
        color: @coral;
      }
    }
  }
  label {
    color: @main;
    font-size: 16px;
  }
  input, select,
  .ant-select-selector {
    min-height: 39px;
    height: auto;
  }
  input, textarea {
    outline: none;
    &:focus:required:invalid {
      border-color: red;
    }
    &:required:valid {
      border-color: green
    }
  }
  .upload-component {
    width: 100%;
  }
  .existingLabel {
    color: @blue;
    font-size: 14px;
    font-weight: 500;
    &__item {
      border-radius: 8px;
      padding: 3px 5px;
      margin-top: 5px;

      &:hover {
        background-color: rgba(@dark-blue, 0.4);
      }

      .anticon-delete {
        color: @coral;
        cursor: pointer;
      }

      &.disabled {
        .anticon-delete {
          cursor: not-allowed;
        }

      }

      strong {
        padding-left: 10px;
        padding-right: 15px;
      }
    }

  }
  .upload-btn {
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    .anticon {
      margin-right: 5px;
    }
    svg {
      color: #000;
    }
  }

}
.ant-btn:focus {
  border-color: @focus;
}
.form-group .invalid-feel {
  margin-top: 3px;
}
.form-group {
  .ant-input-search-button {
    min-height: 39px;
  }
  .ant-select-multiple {
    height: 39px;
  }
  .ant-select, .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    //height: 39px;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 39px;
  }

}
  //number
.ant-input-number-out-of-range input {
  color: @blue;
}
.ant-input-number {
  width: 100%;
}
//checkbox
.checkbox__all {
  border-bottom: 1px solid rgba(@blue, 0.3);
  padding-bottom: 5px;
  margin-bottom: 10px;
}
.ant-checkbox-group {
  display: flex;
  flex-direction: column;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: @green;
  border-color: @green;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: @green;
}
.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: @gray-background
}
//radio
.ant-radio-group {
  display: flex;
  flex-direction: column;
}

//error text
.invalid-feel {
  color: @coral;
}
//password eye
.eye-input {
  position: absolute;
  top: 10px;
  right: 8px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: @black;
  span, svg {
    width: 20px;
    height: 20px;
  }
}
.span.ant-radio {
  label {
    color: @green !important;
  }

}
//pagination
.ant-pagination-item-active {
    background-color: @blue!important;
}
@media (max-width: 700px) {
  .form-group .ant-select-multiple{
    height: auto;
  }
}
