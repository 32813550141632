@import 'src/styles/vars';
.container {
	margin: 50px auto 0;
	padding-left: 50px;
	padding-right: 50px;
	@media (max-width: @sm) {
		padding-left: 15px;
		padding-right: 15px;
	}
	:global(.switch-cell) {
		flex-direction: row;
		align-items: center;
		gap: 8px;
		:global(.ant-switch) {
			background-color: @gray-separator;
		}
		:global(.ant-switch-checked) {
			background-color: @green-yellow;
		}
		:global(.ant-switch-handle::before) {
			background-color: @main;
		}
		:global(small) {
			margin-top: 0;
			color: @gray;
		}
	}
}

.filter {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	.search {
		margin-top: 0;
		margin-bottom: 10px;
		:global(.ant-input) {
			min-height: 31px;
		}
		:global(.ant-input-affix-wrapper) {
			height: 39px;
			border-radius: 5px!important;
		}
	}
	.buttons {
		display: flex;
		gap: 15px;
		:global(.ant-btn) {
			height: 39px;
			border-radius: 5px;
		}
		.second {
			background-color: @green;
			border-color: @green;
			&:hover {
				background-color: @green-yellow;
				border-color: @green-yellow;
			}
		}
	}
}
.prefix {
	color: #dadada;
}
.wrapper {
	display: flex;
	flex-wrap: wrap;
}
.table {
	margin-top: 30px;
}

@primary-color: #0061D9;