@import "src/styles/vars";
.ModalSHD {
  width: 800px!important;
  .ant-modal-title {
    color: @blue;
    font-size: 20px;
  }
  .ant-btn {
    border-radius: 5px;
  }
  &__content {
    padding-right: 100px;
    font-size: 18px;
    color: @text-color-light;
    &__input {
      height: 39px;
      border-radius: 5px;
    }
    .ant-input-prefix {
      margin-right: 15px;
      font-size: 20px;
      svg {
        color: #D9D9D9;
      }
    }
    p {
      font-size: 14px
    }
  }
}

@primary-color: #0061D9;