@import "src/styles/vars";
.root {
  padding: 10px;
}
.info {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 30px;
}
.title {
  color: @blue;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 45%;
  @media (max-width: @sm) {
    width: 100%;
  }
}
.value {
  background-color: @light-fill;
  color: @gray-background;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 11px;
}
@primary-color: #0061D9;