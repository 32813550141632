@import "src/styles/vars";
.root {
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.item {
  .title {
    color: @blue;
    margin-bottom: 15px;
    margin-top: 15px;
    font-size: 12px;
    line-height: 15px;
  }
  &:first-child .title {
    margin-top: 0;
  }
  .users {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 0;
    padding: 0;
    :global(li) {
      background-color: @light-fill;
      color: @gray;
      padding: 4px 7px;
      border-radius: 4px;
      font-size: 11px;
      :global(.anticon) {
        color: @green;
        padding-right: 8px;
      }
    }
  }
}
@primary-color: #0061D9;