@import "src/styles/vars";
.modal-vote {
  width: 1200px !important;
  .name-center-cell {
    color: @gray;
  }
  .name {
    .name-center-cell {
      font-size: 12px;
      color: @focus;
    }
  }

  .ant-modal-title {
    color: @main;
  }
  &__list {
    display: flex;
    flex-direction: column;
    .item {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin-bottom: 6px;
      padding-bottom: 6px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.19);
      line-height: 16px;
      &:last-child {
        border-bottom: none;
      }
      &__header {
        display: flex;
        align-items: center;
        width: 100px;
        span {
          font-size: 12px;
          color: @focus;
        }
      }
      &__content {
        width: 100%;
        display: flex;
        justify-content: space-around;

        .name {
          padding: 0 15px;
          color: @gray;
        }
        .red {
          color: @coral;
          font-size: 16px;
          padding: 0 0 0 5px;
        }
        .green {
          color: @green;
          font-size: 16px;
          padding: 0 0 0 5px;
        }
        .comment {
          color: @gray;
          font-size: 14px;
          flex: 1 1 260px;
          padding: 0 15px;
        }
        .files {
          color: @blue;
          font-size: 14px;
          flex: 1 1 160px;
          position: relative;
          display: flex;
          flex-direction: column;
          gap: 8px;
          @media (max-width: @sm) {
            width: 100%;
          }
          &__item {
            width: 160px;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            @media (max-width: @sm) {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 500px) {
  .modal-vote__list .item {
    flex-direction: column;
    .item__header {
      width: 100%;
    }
    .item__content {
      width: 100%;
      margin-top: 15px;
      flex-wrap: wrap;
      justify-content: flex-start;
      .name {
        padding: 0;
      }
      .comment {
        width: 100%;
        margin-top: 15px;
        padding: 0;
      }
    }
  }
}
@primary-color: #0061D9;