@import 'src/styles/vars';

.modal-user-contact-details {
	width: 1150px !important;

	.title-column {
		span {
			margin-left: 10px;
			font-size: 14px;
		}
		svg {
			color: #0061d9;
		}
	}

	&__title {
		color: @blue;
		span {
			margin-left: 10px;
			color: @main;
		}
	}
	&__tooltip {
		p {
			font-size: 12px;
			color: white;
		}
		.ant-tooltip-open {
			width: 400px;
		}
	}
	.ant-modal-title {
		font-weight: 400;
		font-size: 22px;
		color: @blue;
	}
	&__wrapper {
		display: flex;
		align-items: center;
	}
	&__title-cell {
		width: 100%;
		font-weight: 400;
		display: flex;
		flex-direction: column;
		span {
			font-size: 13px;
			line-height: 16px;
			display: block;
		}
		small {
			font-size: 10px;
			line-height: 12px;
			display: block;
		}
	}
}

@primary-color: #0061D9;