@import "src/styles/vars";

.reload-list {
    position: absolute;
  top: 11px;
  right: 5px;
    cursor: pointer;
    z-index: 10;
    svg {
      font-size: 15px;
      color: @blue;
    }
  &.ident {
    top: 15px;
  }
}

.reload-list + .form-group  {
  .ant-select-arrow,
  .ant-select-clear {
    right: 24px;
  }
}

@primary-color: #0061D9;