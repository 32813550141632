@import "src/styles/vars";
.root {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 8px 16px;
  @media( max-width: @md) {
    flex-wrap: wrap;
  }
  :global(label) {
    font-size: 14px;
    color: @gray-background;
    display: block;
    white-space: nowrap;
    @media (max-width: @sm) {
      white-space: normal;
    }
  }
  .input {
    height: 32px;
  }
}
@primary-color: #0061D9;