@import "src/styles/vars";
.modal-comment {
  width: 600px !important;
  .ant-modal-title {
    color: @blue;
    font-size: 16px;
    line-height: 25px;
    > span:last-child{
      color: @gray;
    }
  }
  .item__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    .form-group {
      width: 100%;
      font-size: 16px;
      .count {
        color: rgba(0, 0, 0, 0.68);
        margin-top: 5px;
        font-size: 12px;
        padding: 0;
      }
    }
  }
  .ql-container {
    min-height: 100px;
  }
}

@primary-color: #0061D9;