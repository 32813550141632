@import "src/styles/vars";
.container {
  margin-bottom: 20px;
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: auto;
  grid-template-rows: auto;
  gap: 10px 5px;
  grid-template-areas:
    "first_label first first switch second_label second second";
  @media(max-width: 980px) {
    margin-bottom: 50px;
    grid-template-areas:
    "first_label first"
    "switch switch"
    "second_label second";
  }
  @media(max-width: 600px) {
    padding-top: 30px;
    grid-template-areas:
    "first_label"
    "first"
    "switch"
    "second_label"
    "second";
  }
  :global(.ant-select-selection-overflow-item) {
    .code {
      display: none;
    }
  }
  :global(.ant-select) {
    width: 100%;
  }
}
:global(.ant-select-item-option-selected) {
  .code {
    background-color: #e6f5ff;
  }
}
.code {
  position: absolute;
  right: 0;
  top: 0;

  padding: 9px 10px 9px 5px;
  border-radius: 12px 0 0 12px;

  background-color: #fff;
}
.checkbox {
  margin-top: 10px;
}
.checkboxInSelect {
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 5px;
  width: 100%;
  border-bottom: 1px solid rgba(@gray, 0.3);
}
.switch {
  padding-left: 15px;
  padding-right: 15px;
  grid-area: switch;
  display: flex;
  justify-content: center;
  width: 133px;
  margin-top: 10px;
  @media(max-width: 980px) {
    margin-top: 0;
    align-items: center;
    width: 100%;
  }
}

.first_label { grid-area: first_label; width: 166px }
.first { grid-area: first; }

.second_label { grid-area: second_label; width: 166px }
.second { grid-area: second; }

.rhombus {
  display: block;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  background-color: #fff;
  &:global(.ACTIVE) {
    background-color: @green;
  }
  &:global(.ARCHIVE) {
    background-color: @orange;
  }
  &:global(.BLOCKED) {
    background-color: @gray-background;
  }
}
@primary-color: #0061D9;