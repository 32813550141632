@import "src/styles/vars";
.modal {
  padding: 30px;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .title {
      max-width: 80%;
      overflow: hidden;
      overflow-wrap: break-word;
      text-wrap: balance;
      @media (max-width: @sm) {
        max-width: 100%;
      }
    }
    .sprint {
      font-size: 16px;
      &:global(.ACTIVE) {
        color: @orange;
      }
      &:global(.CLOSED) {
        color: @green;
      }
      &:global(.ENDED_OPEN) {
        color: @coral;
      }
      &:global(.INACTIVE) {
        color: @gray;
      }
    }
  }
  :global(.ant-pagination) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  :global(.ant-pagination-options) {
    margin-top: 15px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

@primary-color: #0061D9;