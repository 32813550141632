@import 'src/styles/vars';
.container {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: auto;
  grid-template-rows: auto;
  gap: 15px 5px;
  grid-template-areas:
    "channels_label channels_label channels  channels channels"
    "kpis_label kpis_label kpis kpis kpis"
    "image_label image_label image image image";
  @media(max-width: 1356px) {
    grid-template-rows: auto;
    grid-template-areas:
    "channels_label channels channels  channels channels"
    "kpis_label kpis kpis kpis kpis"
    "image_label image image image image";
  }
  @media(max-width: 980px) {
    grid-template-areas:
    "channels_label channels channels  channels"
    "kpis_label kpis kpis kpis"
    "image_label image image image";
  }
  @media(max-width: 600px) {
    padding-top: 30px;
    grid-template-areas:
    "channels_label"
    "channels"
    "kpis_label"
    "kpis"
    "image_label"
    "image";
  }
  :global {
    .ant-btn-link:hover, .ant-btn-link:focus {
      border-color: transparent;
    }
  }
}
.text_right {
  text-align: right;
  @media(max-width: 900px) {
    text-align: left;
  }
}
.checkbox {
  margin-top: 10px;
}
.btnAdd {
  width: 100%;
}
.labelSmall {
  font-size: 14px;
}
.delete {
  color: @coral;
  height: 43px;
  font-size: 16px;
  &:hover {
    color: @dark-coral;
  }
}
.error {
  font-size: 12px;
  color: @coral;
}
.channels_label { grid-area: channels_label; }
.channels { grid-area: channels; }

.kpis_label { grid-area: kpis_label; }
.kpis { grid-area: kpis; }

.image { grid-area: image; }
.image_label { grid-area: image_label; }



@primary-color: #0061D9;