@import "src/styles/vars";
.modal-contentTask {
  width: clamp(300px, 100%, 900px) !important;
  .notTouch {
    display: none !important;
  }
  .ant-modal-header, .ant-modal-body {
    background-color: #111;
  }
  .ant-modal-header {
    border-bottom: 1px solid @blue;
  }
  .ant-modal-close {
    color: #F2F2F2;
  }
  .ant-modal-title {
    font-size: 20px;
    color: #F2F2F2;
    font-weight: 400;
  }
}
.modal-contentTask {
  .timeCodesList {
    margin-top: 20px;
    padding: 0;
    list-style: none;
    color: #fff;
    li {
      padding: 0 0 0 5px;
      margin: 10px 0 0;
      font-size: 14px;
      .time {
        color: @blue;
        transition: 0.3s ease all;
        cursor: pointer;
        &:hover {
          color: @dark-blue;
        }
      }
    }
  }
  &__content {
    &-image {
      width: 100%;
      max-height: 350px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        object-fit: contain;
        max-height: 350px;
      }
    }
    &-video {
      video {
        height: 100%;
        max-height: 400px;
      }
    }
    &-pdf {
      font-size: 20px;
      text-align: center;
      .anticon {
        font-size: 25px;
      }
      a {
        color: @blue;
        transition: 0.3s ease all;
        &:hover {
          color: @dark-blue;
        }
      }
    }
  }
}

@primary-color: #0061D9;