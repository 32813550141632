@import "src/styles/vars";
.item {
  text-align: center;
  &:global(.TO_IMPLEMENTATION) {
    color: @gray;
  }
  &:global(.IN_PROGRESS) {
    color: @blue
  }
  &:global(.READY) {
    color: @green;
  }
}
@primary-color: #0061D9;