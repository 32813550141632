@import 'src/styles/vars';
.container {
	padding: 15px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	:global(hr) {
		border: 0;
		background-color: #f0f0f0;
		height: 1px;
		width: 100%;
	}
}
.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 20px;
	.title {
		font-size: 20px;
		line-height: 24px;
		color: @blue;
	}
	:global(.form-group) {
		flex-direction: row;
		align-items: center;
		gap: 8px;
		margin-top: 0;
		:global(label) {
			color: @gray-background;
		}
		:global(.ant-switch-checked) {
			background-color: @green-yellow;
		}
		:global(.ant-switch-handle::before) {
			background-color: @main;
		}
	}
}
.picker {
	width: 100%;
}
.item {
	display: flex;
	align-items: center;
	width: 100%;
	gap: 15px;
	:global(.form-group) {
		margin-top: 0;
		width: 100%;
		font-size: 12px;
	}
	:global(.form-group label) {
		font-size: 12px;
	}
	:global(.ant-picker) {
		height: 39px;
		width: 100%;
	}
	:global(.ant-radio-group) {
		flex-direction: row;
	}
	.label {
		width: 45%;
		font-size: 14px;
		line-height: 18px;
		:global(sup) {
			color: @coral;
			font-size: 14px;
		}
	}
	.field {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 8px;
		:global(.ant-select-selector, .ant-select-selection-overflow) {
			max-height: 39px !important;
		}
		&__item {
			display: flex;
			gap: 10px;
			align-items: center;
			margin-bottom: 5px;
		}
		.counter {
			white-space: nowrap;
		}
		.add {
			display: flex;
			align-items: center;
			gap: 5px;
			font-size: 16px;
			transition: 0.3s ease all;
			cursor: pointer;
			color: @blue;
			&:global(.disabled) {
				color: @gray-background;
				cursor: not-allowed;
				&:hover {
					color: @gray-background;
				}
			}
			&:hover {
				color: @dark-blue;
			}
		}
		.remove {
			color: @coral;
			transition: 0.3s ease all;
			&:hover {
				color: @dark-coral;
			}
		}
	}
}
.buttons {
	margin-top: 20px;
	display: flex;
	justify-content: flex-end;
	gap: 10px;
}
.error {
	color: @coral;
}

@primary-color: #0061D9;