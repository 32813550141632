@import "src/styles/vars";
.projectPage {
  &__card {
    .mt15 {
      margin-top: 15px;
    }
    margin-top: 30px;
    padding: 0 50px;
    justify-content: space-between;
    &-img {
      margin-bottom: 20px;
      img {
        display: block;
        max-width: 100%;
      }
      span {
        color: @gray-background;
        font-size: 11px;
        line-height: 17px;
      }
    }
    &-info,
    &-title {
      font-size: 16px;
      line-height: 20px;
      color: @black;
      padding-bottom: 10px;
      display: flex;
      width: 100%;
      .anticon {
        margin-right: 8px;
        font-size: 20px;
        color: @green !important;
      }
    }
    &-text {
      li {
        color: @gray;
        font-size: 15px;
      }
      p {
        color: @gray;
        font-size: 15px;
        line-height: 20px;
      }
    }

  }
}
@media (max-width: 760px) {
  .projectPage__card-left {
    display: flex;

    .projectPage__card-img {
      width: 50%;
    }
  }
}

@media (max-width: 440px) {
  .projectPage__card {
    overflow: hidden;
    padding: 0 15px;
  }

  .projectPage__card-left {
    display: flex;
    flex-wrap: wrap;

    .projectPage__card-img {
      width: 100%;
    }
  }
}

@primary-color: #0061D9;