@import "src/styles/vars";
.team {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  .title {
    width: 100%;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    @media (max-width: 990px) {
      flex-wrap: wrap;
    }
    @media (max-width: 500px) {
      justify-content: center;
    }
  }
  .item {
    margin: 0 25px 5px 0;
    &:first-child {
      margin-left: 0;
    }
    &:first-child {
      margin-right: 0;
    }
    @media (max-width: 990px) {
      margin-bottom: 15px;
    }
    @media (max-width: 500px) {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin: 0 0 15px;
      padding: 5px;
      border: 1px solid rgba(@blue, 0.3);
      border-radius: 8px;
    }
    &__header {
      display: flex;
      align-items: center;
      @media (max-width: 500px) {
        width: 40%;
        padding-right: 15px;
      }
      @media (max-width: 450px) {
        width: 100%;
      }
      img {
        display: block;
        width: 50px;
        min-width: 50px;
        height: 50px;
        margin-right: 10px;
        border-radius: 50%;
        overflow: hidden;
        object-fit: cover;
      }
      span {
        font-size: 12px;
        color: @main;
      }
    }

    &__content {
      margin-top: 15px;
      span {
        color: @blue;
      }
      a {
        color: @gray-background;
      }
      @media (max-width: 500px) {
        width: 60%;
        margin-top: 0;
      }
      @media (max-width: 450px) {
        width: 100%;
        margin-top: 15px;
      }

      ul {
        margin: 10px 0 0;
        padding: 0;
        list-style: none;
        font-size: 12px;

        li {
          line-height: 15.6px;
          margin-top: 10px;

          .anticon {
            color: @black;
            margin-right: 5px;
          }
        }
      }
    }
  }
}
@primary-color: #0061D9;