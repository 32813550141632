@import 'src/styles/vars';
.download {
	display: flex;
	justify-content: center;
	align-items: center;
	:global(.success) {
		svg {
			color: @blue!important;
		}
	}
	:global(.block) {
		svg {
			color: @coral!important;
		}
	}
}

@primary-color: #0061D9;