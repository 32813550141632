@import 'src/styles/vars';

.ContentItemPage {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0 50px;
	overflow-x: auto;
	.comeBackBtn {
		span {
			font-size: 20px;
			line-height: 20px;
		}

		a {
			color: @blue !important;
		}
	}

	&__header {
		margin-top: 20px;
		width: 100%;

		&-top {
			display: flex;
			flex-direction: column;
			width: 100%;
			justify-content: space-between;
			@media (min-width: @md) {
				flex-direction: row;
			}
			.title {
				font-size: 25px;
				line-height: 29px;
				color: @main;
			}

			.status {
				color: @gray;
				span {
					font-size: 16px;
					&.COMPLETED {
						color: @green;
					}
					&.DRAFT {
						color: @draft;
					}
					&.SUSPENDED {
						color: @coral;
					}
					&.ACTUAL {
						color: @light-orange;
					}
				}
			}
		}

		&-bottom {
			margin-top: 15px;
			color: #dadada;
			font-size: 16px;
			line-height: 19px;
		}
	}

	&__content {
		width: 100%;
		margin-top: 15px;
		display: flex;
		flex-wrap: wrap;
		&-player {
			position: relative;
			width: 50%;
			min-width: 300px;
			@media (max-width: 750px) {
				width: 100%;
			}
		}

    &-description {
      padding-left: 15px;
      width: 50%;
      @media (max-width: 750px) {
        width: 100%;
        margin-top: 30px;
      }
      .title {
        color: @main;
        font-size: 16px;
        font-weight: 600;
      }
      .item {
        margin-bottom: 15px;
        .blue {
          color: @blue;
        }
        li {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.4);
        }
        &.tags {
          span, strong {
            font-size: 16px;
          }
        }
        .tags {
          &__content {
            display: flex;
            flex-direction: column;
            &-amount {
              display: flex;
              align-items: center;
              justify-content: center;
              color: @blue;
              border: 1px solid  #F0F0F0;
              background-color:  #F0F0F0;
              margin-top: 5px;
              margin-left: 5px;
              margin-right: 3px;
              width: 18px;
              height: 18px;
              font-size: 11px;
              border-radius: 50%;
              flex-shrink: 0;
              cursor: pointer;
              z-index: 2;
              span {
                margin-top: 0;
                margin-left: 0!important;
                font-size: 11px;
              }
            }
            &-item {
              display: flex;
              align-items: flex-start;
              &:first-child {
                margin-right: 30px;
              }
              span {
                margin-left: 5px;
                color: @green;
              }
              strong {
                flex-shrink: 0;
                font-weight: 400;
              }
            }

          }
        }
        &-ul {
          margin: 10px 0 0;
          padding: 0;
          list-style: none;
          display: flex;
          flex-wrap: wrap;
        }
        &-li {
          margin: 15px 20px 0 0;
          padding: 0;
          color: @blue;
          display: flex;
          align-items: center;
          strong {
            text-align: center;
            margin-left: 10px;
            margin-right: 5px;
            height: 30px;
            min-width: 30px;
            padding: 10px;
            border-radius: 50%;
            background: @green-yellow;
            color: @main;
            font-size: 10px;
            line-height: 10px;
          }
          small {
            color: #76767A;
          }
        }
        .ant-collapse-header {
          font-size: 16px;
          font-weight: 600;
        }
        .ant-collapse-content-box p{
          font-size: 16px;
          line-height: 18px;
          color: rgba(0, 0, 0, 0.4);
        }
      }

			.links {
				display: flex;
				margin-top: 15px;
				&__item {
					cursor: pointer;
					margin-right: 15px;
					background: @blue;
					transition: 0.3s ease-in all;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 40px;
					height: 40px;
					border-radius: 50%;
					font-weight: 500;
					color: @white;
					&:hover {
						background: @dark-blue;
					}
				}
			}
		}
	}

	&__footer {
		margin-top: 15px;
		width: 100%;
		&__cards {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 20px;
		}
        &__empty,
		&__pagination {
			display: flex;
			justify-content: center;

			margin-bottom: 60px;
		}
	}
}
@primary-color: #0061D9;