@import 'src/styles/vars';

.select_wrapper {
	width: 100%;
	margin-bottom: 10px;
	:global(.ant-select-selector) {
		min-height: 39px !important;
		height: auto !important;
		border-radius: 5px !important;
	}
}
.select {
	width: 100%;
}

@media (min-width: @md) {
	.select_wrapper {
		max-width: 250px;
		margin-left: 10px;
		:global(.ant-select-selector) {
			min-height: 39px !important;
			height: auto !important;
		}
	}
	.select {
		max-width: 250px;
	}
}

@primary-color: #0061D9;