@import "src/styles/vars";

.taskForm {
  width: 100%;
  .anticon-delete {
    color: @coral;
  }
  .ant-switch-checked {
    background-color: @green-yellow;
  }
  .taskForm-form {
    width: 100%;
    padding: 0 15px;
    &__mobile--checkbox {
      flex-direction: column;
    }
    &__checkbox {
      display: flex;
      flex-wrap: wrap;
      &--item {
        display: flex;
        align-items: center;

        .span {
          margin-right: 15px;
          color: @blue;
          font-size: 16px;
          font-weight: 400;
        }
        label {
          span {
            margin-right: 2px;
          }
        }
        .form-group {
          width: auto!important;
        }
      }
    }
    .label {
      color: @main;
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      &.nowrap {
        white-space: nowrap;
      }

      sup {
        color: @coral;
        font-weight: 700;
      }
      &-right {
        text-align: right;
        padding-right: 15px;
      }
    }
    .label-small {
      margin-top: 3px;
      font-size: 14px;
    }
    .disabled {
      color: @disabled;
    }
    .mt15 {
      margin-bottom: 15px;
    }
    .form-group {
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      margin-top: 0;
      .ant-select-multiple,
      .ant-select {
        width: 100%;
      }
      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 41px;
      }
      &__quill {
        .invalid-feel {
          padding-left: 0;
        }
      }
      &__col {
        display: flex;
        justify-content: center;
        align-items: center;
        .form-group {
          margin: 0 auto;
          width: auto;
        }
      }
    }
    label {
      color: @blue;
      font-size: 16px;
      font-weight: 400;
    }
    .ant-radio-group {
      flex-direction: row;
      label {
        width: 48%;
      }
    }
    .ant-picker {
      width: 100%;
    }
    &__isContent {
      .ant-radio-group {
        width: 100%;
      }
      span.ant-radio + * {
        white-space: nowrap;
      }
    }
    &__item {
      margin-top: 20px;
      display: flex;
      &__banner {
        display: block;
        font-size: 16px;
        margin-bottom: 20px;
      }
      sup {
        font-size: 16px;
      }
      .contractor-organization {
        padding: 5px;
        color: @blue;
        font-size: 16px;
        font-weight: 400;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
      }
      &.child {
        margin-top: 0;
      }
      &.btn {
        span {
          color: @blue;
          font-size: 16px;
          line-height: 18px;
        }

      }
      .descriptionContent {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: nowrap;
        &__wrapper {
          display: inline-block;
          text-align: left;
          height: 36px;
          vertical-align: -2px;
          @media(min-width: 663px) {
            margin-left: 15px;
          }
        }
        &__text {
          color: rgba(15, 16, 17, 0.5);
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
    &__buttons {
      margin-top: 50px;
      display: flex;
      justify-content: flex-end;
      button {
        font-size: 12px;
        line-height: 12px;
        padding: 10px 15px;
        height: auto;
        margin-left: 15px;
        border-radius: 5px;
        &:first-child {
          margin-left: 0;
        }
        &.arrowBtn__mr {
          margin-right: 15px;
        }
      }
    }
    &__radio {
      .ant-radio-group {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
      .ant-radio-wrapper {
        width: 180px !important;
        background-color: #fff;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        color: @blue;
        border-radius: 4px;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 5px;
        &.ant-radio-wrapper-checked {
          background-color: rgba(@blue, 0.6);
          color: #fff;
        }
      }
      .ant-radio {
        display: none;
      }
      .ant-radio + span {
        font-size: 15px;
        font-weight: 400;
      }
    }
  }
}

@media (max-width: 700px) {
  .taskForm {
    .taskForm-form {
      &__checkbox {
        flex-direction: column;
      }
    }
  }
}


@media (max-width: 810px) {
  .taskForm .taskForm-form__isContent .ant-radio-group {
    flex-wrap: wrap;
  }
}

@primary-color: #0061D9;