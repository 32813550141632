@import "src/styles/vars";
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  .item {
    font-size: 20px;
    cursor: pointer;
    color: #D9D9DE;
    &:global(.active) {
      color: rgba(@coral, 50%);
    }

  }
}
@primary-color: #0061D9;