@import "../../../../../styles/vars";

.contacts-cell {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  &__item {
    padding: 2px 7px;
    margin-bottom: 5px;
    margin-right: 5px;
    background: #F5F5F5;
    border-radius: 5px;
    text-align: center;
  }
  &__general {
    background-color: rgba(208, 240, 83, 0.28);
  }
}


@primary-color: #0061D9;