@import "src/styles/vars";
.form {
  width: 100%;
  :global(sup) {
    color: @coral;
    font-size: 16px;
  }
  :global(.form-group) {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 0;
  }
  :global(
    .ant-select-multiple,
    .ant-select) {
      width: 100%;
  }
  :global(.ant-picker) {
    width: 100%;
    height: 39px;
  }
  :global(.ant-input-number) {
    height: 39px;
  }
  :global(.ant-input-affix-wrapper) {
    width: 100%;
    height: 39px;
    padding: 0 5px;
    :global(.ant-input) {
      height: 37px;
      min-height: 37px;
    }
  }
  :global(.form-group__quill) {
    :global(.invalid-feel) {
      padding-left: 0;
    }
  }
  :global(.label) {
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
  }
  &:global(.label-right) {
    text-align: right;
    padding-right: 15px;
  }
  :global(.ant-radio-group) {
    flex-direction: row;
    flex-wrap: wrap;
    :global(label) {
      width: 48%;
    }
  }
}
.buttons {
  margin-top: 50px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 10px 15px;
  .unionSave {
    background-color: @blue;
    border-color: @blue;
  }
  :global(button) {
    font-size: 12px;
    line-height: 12px;
    padding: 10px 15px;
    height: auto;
    border-radius: 5px;
    &:first-child {
      margin-right: 20px;
    }
  }
}
@primary-color: #0061D9;