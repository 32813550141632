.font(@fname, @fstyle, @fweight, @furl) {
  @font-face {
    font-family: @fname;
    font-style: @fstyle;
    font-weight: @fweight;
    src: url(@furl) format('woff2');
  }
}

.font('golos', normal, 400, '../../assets/fonts/Golos_Text_Regular.woff2');
.font('golos', normal, 500, '../../assets/fonts/Golos_Text_Medium.woff2');
.font('golos', normal, 600, '../../assets/fonts/Golos_Text_DemiBold.woff2');
.font('golos', normal, 700, '../../assets/fonts/Golos_Text_Bold.woff2');
.font('golos', normal, 900, '../../assets/fonts/Golos_Text_Black.woff2');