@import "src/styles/vars";

.infoMenu {
  height: 100%;
  display: flex;
  align-items: center;
  &__loadBtn,
  &__unfinished {
    margin-right: 15px;
    position: relative;
    height: 53px;
    overflow: hidden;
    cursor: pointer;
  }
  &__loadBtn {
    width: 37px;
    &-img {
      position: absolute;
      top: 0;
      left: 0;
      max-width: 100%;
      display: block;
      object-fit: cover;
    }
    &-progress {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 3px;
      .ant-progress {
        display: flex;
        flex-direction: column;
      }
      .ant-progress-text {
        order: 0;
        margin-left: 0;
        font-size: 10px;
        align-self: center;
        width: auto;
      }
      .ant-progress-outer {
        order: 1;
        padding: 0 3px;
      }
    }
  }
  &__unfinished {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    .anticon {
      font-size: 22px;
      color: @blue;
      transition: 0.3s ease-in all;
    }
    &:hover {
      .anticon {
        color: @dark-blue;
      }
    }
  }
  &__tour {
    cursor: pointer;
    margin-right: 15px;
    font-size: 20px;
    color: @coral;
    @media (max-width: 400px) {
      .icon {
        display: none;
      }
    }
  }
  &__support,
  &__archive {
    margin-right: 15px;
      width: 22px;
      height: 22px;
      .anticon {
        width: 22px;
        height: 22px;
        cursor: pointer;
        svg {
          width: 22px;
          height: 22px;
          transition: 0.3s ease-in-out all;
          color: @blue;
        }
      }
      &:hover {
        svg {
          color: @dark-blue;
        }
      }
  }
  &__notification {
    margin-right: 15px;
    .ant-badge {
      width: 22px;
      height: 22px;
      .anticon {
        width: 22px;
        height: 22px;
        cursor: pointer;
        svg {
          width: 22px;
          height: 22px;
          transition: 0.3s ease-in-out all;
          color: @blue;
        }
      }
      &:hover {
        svg {
          color: @dark-blue;
        }
      }
    }
  }
  &__sidebar {
    width: 25px;
    height: 25px;
    cursor: pointer;
    img {
      width: 25px;
      height: 25px;
    }
  }
  &__user {
    margin-left: 20px;
    width: 53px;
    height: 53px;
    border-radius: 50%;
    background: #F8F8F8;
    border: 2px solid @blue;
    color: @blue;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 24px;
    line-height: 53px;
    cursor: pointer;
    transition: 0.3s ease-in-out all;
    &:hover {
      background: @green;
      border-color: @main;
      color: @main;
    }
    img {
      width: 52px;
      height: 52px;
      display: block;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  &__setting,
  &__logout,
  &__menu {
    width: 31px;
    height: 31px;
    cursor: pointer;
    margin-left: 20px;
    svg {
      width: 31px;
      height: 31px;
      color: @blue;
      transition: 0.5s ease-in-out all;
    }
    &:hover {
      svg {
        color: @main;
      }
    }
  }
  &__setting {
    border-radius: 50%;
    &:hover {
      svg {
        transform: rotate(180deg);

      }
    }
  }
  &__menu {
    display: none;
  }
  &__setting-wrapper {
    width: 53px;
    height: 53px;
    margin-left: 10px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: #F8F8F8;
    }
  }
}
@media (max-width: 978px) {
  .infoMenu {
    &__setting {
      display: none;
    }

    &__menu {
      display: block;
    }
  }
}

@media (max-width: 400px) {
  .infoMenu__unfinished {
    display: none;

    &.mobile {
      display: block;
      margin-top: 15px;
      overflow: inherit;
    }
  }

}

@primary-color: #0061D9;