.popover_content {
  padding: 15px;
  min-width: 300px;
  :global(.form-group) {
    margin-top: 5px;
  }
  .popover_item {
    margin-bottom: 10px;
  }
}
@primary-color: #0061D9;