@import "src/styles/vars";
.container {
  width: 100%;
  display: flex;
}
.icon {}
.removeRelation {
  position: absolute;
  right: 15px;
  color: @coral;
}
.select {
  border-right-color: transparent !important;
  :global(.ant-select-selector) {
    box-shadow: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 39px;
    position: relative;
    text-align: center;
  }
  &:global(.ant-select) {
    width: 100%;
    :global(.ant-select-selection-item) {
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 39px;
      position: relative;
      .label {
        max-width: 150px;
      }
      .icon {
        display: none;
      }
    }
    :global(.ant-select-selector) {
      background-color: @light-fill;
      border: none;
      border-radius: 4px;
      height: 39px;
    }
  }
}
.option {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: space-between;
  color: #8B9DAB;
}
.label {
  max-width: 180px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}
.dropdown {
  .removeRelation {
    display: none;
  }
}
.buttons {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 15px;

  .editBtn {
    color: @blue;
  }
  .deleteBtn {
    color: @coral;
  }
  .save {
    color: @green;
    &:global(.disabled) {
      color: @gray-background;
      cursor: not-allowed;
    }
  }
  .cancel {
    color: @coral;
  }
}
.newBtn {
  display: flex;
  align-items: center;
  gap: 4px;
  transition: 0.3s ease all;
  width: 100%;
  padding: 4px 8px;
  cursor: pointer;
  color: #8B9DAB;
  &:hover {
    color: @blue;
  }
}
.editFeel {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px;
}
@primary-color: #0061D9;