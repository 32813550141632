@import "src/styles/vars";
.main {
  display: flex;
  justify-content: center;
}

.red,
.green {
  font-size: 16px;
  padding: 0 0 0 5px;
}

.red {
  color: @coral;
}

.green {
  color: @green;
}
@primary-color: #0061D9;