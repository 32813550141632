@import "src/styles/vars";
.modal {
  padding: 10px 33px;
  min-height: 100px;
  overflow: hidden;
  :global(.ql-container) {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  :global(.ql-toolbar) {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .create {
    display: flex;
  }
  .empty {
    margin: 40px;
  }
  .button_containerArea,
  .button_container {
    overflow: hidden;
    margin-bottom: 10px;
  }
  .update_container {
    display: flex;
  }
  .update {
    max-width: 100%;
    flex-grow: 1;
    overflow: hidden;
  }
  .add {
    padding: 5px;
    border-radius:5px;
    color: #C8C8CB;
    cursor: pointer;
    border: 1px solid #C8C8CB;
    margin-bottom: 20px;
    flex-grow: 1;
    margin-left: 10px;
  }
  .create_area {
    flex-grow: 1;
  }

  .button_cancel,
  .button_delete,
  .button_change,
  .button_save {
    border-color: @white;
    color: @blue;
    margin-bottom: 10px;
  }
  .button_cancel {
    color: @gray;
  }
  .button_change {
    color: @blue;
  }
  .button_delete {
    color: @coral;
  }
  .show_more {
    display: flex;
    padding: 5px;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: @light-fill;
    color: @blue;
    border-radius: 5px;
    margin-bottom: 10px;
    border: 1px solid transparent;
  }
  &:global(.ant-btn) {
    box-shadow: none;
  }
  .limit {
    margin: 0;
    padding: 0;
    text-align: right;
    font-size: 10px;
    font-weight: 400;
    color: @coral;
  }
  :global(.disabled) {
    background-color: @white!important;
    color: @gray!important;
  }

}
.circle {
  min-height: 30px;
  min-width: 30px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: @gray-separator;
  margin-right: 25px;
}
.comment {
  display: flex;
  margin-bottom: 15px;
  .info_container {
    flex-grow: 1;
  }

  .info {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    margin-bottom: 10px;
  }
  .text {
    display: block;
    max-width: 100%;
    flex-wrap: wrap;
    overflow: hidden;
    margin-left: 12px;
    font-size: 12px;
    color: @gray;
    p {
      max-width: 100%;
    }
  }
  .date,
  .name {
    margin-left: 12px;
    font-weight: 700;
    font-size: 12px;
    color: @gray;
  }
  .date {
    font-weight: 400;
  }

}
&:global(.ant-btn) {
  box-shadow: none;
}
@primary-color: #0061D9;