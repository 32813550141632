@import "src/styles/vars";
.comeBackBtn {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  .link {
    color: @blue;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;
    transition: 0.3s ease all;
    @media (max-width: 500px) {
      font-size: 18px;
      line-height: 18px;
    }
    &:hover {
      color: @dark-blue;
    }
  }
}

@primary-color: #0061D9;