@import "src/styles/vars";
.container {
  margin: 50px auto 0;
  padding-left: 50px;
  padding-right: 50px;
  @media(max-width: @sm) {
    padding-left: 15px;
    padding-right: 15px;
  }
  :global(.switch-cell) {
    flex-direction: row;
    align-items: center;
    gap: 8px;
    :global(.ant-switch) {
      background-color: @gray-separator;
    }
    :global(.ant-switch-checked) {
      background-color: @green-yellow;
    }
    :global(.ant-switch-handle::before) {
      background-color: @main;
    }
    :global(small) {
      margin-top: 0;
      color: @gray;
    }
  }
}

.filter {
  display: flex;
  justify-content: space-between;
  @media(max-width: 1210px) {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .search {
    margin-top: 0;
    :global(.ant-input) {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      min-height: 31px;
    }
    :global(.ant-input-affix-wrapper) {
      height: 39px;
    }
    :global(.ant-input-search-button) {
      border-top-right-radius: 5px !important;
      border-bottom-right-radius: 5px !important;
    }
  }
  .buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    @media(max-width: 1210px) {
      margin-top: 10px;
    }
    :global(.ant-btn) {
      height: 39px;
      border-radius: 5px;
    }
    .second {
      background-color: @green;
      border-color: @green;
      &:hover {
        background-color: @green-yellow;
        border-color: @green-yellow;
      }
    }
  }
}
.table {
  margin-top: 30px;
}
.wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
}
.radio_group {
  display: flex;
  flex-direction: row;
}
@primary-color: #0061D9;