.tooltip__container {
  position: absolute;
  top: 0;
  left: 50%;
  height: 70px;
  transform: translateX(-50%);
}
.tooltip__image {
  max-width: 350px;
  :global(img) {
    display: block;
    max-width: 100%;
  }
}
@primary-color: #0061D9;