@import "src/styles/vars";

.VoteCell {
  width: 100%;
  display: flex;

  justify-content: space-around;
  :global(.anticon) {
    font-size: 20px;
  }
  .left,
  .right {
    position: relative;
    z-index: 1;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .left {
    color: @blue;
  }
  .right {
    color: @blue;
    border-left: 1px solid rgba(0, 0, 0, 0.11);
    display: flex;
    gap: 10px;
  }
  .icon__group {
    position: relative;
    display: flex;
    flex-direction: column;
    .btn {
      display: flex;
      justify-content: center;
      gap: 10px;
    }
    .count {
      color: @blue;
      margin-top: 8px;
      text-align: center;
      cursor: pointer;
      transition: 0.3s ease-in all;
      &:hover {
        color: @dark-blue;
      }
    }
  }
  .icon {
    cursor: pointer;
    transform: scale(1);
    transition: 0.3s ease-in all;
    &:global(.red) {
      color: @coral;
    }
    &:global(.green) {
      color: @green;
    }
    &:global(.yellow) {
      color: @orange;
    }
    &:hover {
      transform: scale(1.1);
    }
    &:global(.disabled) {
      color: #dadada !important;
      :global(.anticon) {
        transform: scale(1);
      }
    }
  }
}


@primary-color: #0061D9;