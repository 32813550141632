@import "src/styles/vars";
.projectBudget {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  &__item {
    margin-right: 30px;
    margin-bottom: 20px;
    line-height: 20px;
    .title {
      display: flex;
      align-items: center;
      color: @black;
      font-size: 15px;
      margin-bottom: 15px;
      .anticon {
        color: @green;
        font-size: 20px;
        margin-right: 8px;
      }
    }
    .value {
      color: @blue;
      font-size: 16px;
      font-weight: 400;
    }
    .description {
      font-size: 11px;
      line-height: 15px;
      color: @gray-background;
    }
    .thoItems {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
@primary-color: #0061D9;