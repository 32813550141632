@import "src/styles/vars";
.ModalLogout {
  .ant-modal-title {
    color: @blue;
    font-size: 20px;
  }
  .ant-btn {
    border-radius: 5px;
  }
  &__content {
    font-size: 18px;
    color: @black;
  }
}

@primary-color: #0061D9;