@import "src/styles/vars";
.buttonCustom {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button {
  min-width: 240px;
  background-color: @gray-separator;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  gap: 8px;
  color: @blue;
  padding: 8px;
  height: auto;
  &:focus {
    background-color: @gray-separator;
    border: 1px solid transparent;
  }
}
.count {
  :global(.ant-badge-count) {
    background: @gray-separator;
  }
}
.modal {
  :global(.ant-modal-title) {
    color: @blue;
    font-size: 20px;
  }
  :global(.ant-modal-content) {

  }

}
@primary-color: #0061D9;