@import "src/styles/vars";
.mainMenu {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  position: relative;
  ul {
    display: flex;
    flex-wrap: wrap;
  }
  @media (max-width: 978px) {
    justify-content: center;
    .mainMenu {
      &__report {
        a {
          padding: 5px 0;
        }
      }
    }
  }
  ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    @media (max-width: 978px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      li {
        margin-right: 0;
        margin-bottom: 15px;
      }
    }
    li {
      margin-right: 15px;
      a {
        color: @blue;
        font-size: 18px;
        padding: 5px;
        position: relative;
        transition: 0.3s ease all;
        &:before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 10%;
          right: 10%;
          height: 3px;
          background-color: transparent;
          transition: 0.3s ease all;
        }
        &.active {
          color: @main;
          &:before {
            background-color: @green-yellow;
          }
        }
        &:hover {
          color: @main;
          &.active {
            color: @dark-blue;
          }
          &:before {
            left: 0;
            right: 0;
          }
        }
      }
    }
    .mainMenu-reporting {
      a {
        &:before {
          left: 5%;
          right: 5%;
        }
      }
    }
  }
}

@primary-color: #0061D9;