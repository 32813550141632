@import "src/styles/vars";
.ProjectCardInfo {
  display: flex;
  flex-direction: column;
}
.ProjectInfo {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  &__group {
    margin-bottom: 30px;
  }
  &__item {
    margin-right: 20px;
    margin-bottom: 10px;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    &__amount {
      display: flex;
      align-items: center;
      justify-content: center;
      color: @blue;
      border: 1px solid  #F0F0F0;
      background-color:  #F0F0F0;
      margin-left: 5px;
      margin-right: 3px;
      width: 18px;
      height: 18px;
      font-size: 9px;
      border-radius: 50%;
      flex-shrink: 0;
      cursor: pointer;
      z-index: 2;
    }
    &--width100 {
      width: 100%;
    }
    .element {
      width: 100%;
      margin-bottom: 8px;
      .element-span {
        display: inline-flex;
      }
    }
    .title {
      color: @black;
      width: 100%;
      margin-bottom: 15px;
      .anticon {
        color: @green;
        margin-right: 10px;
      }
    }
    .name {
      color: @black;
      margin-right: 10px;
      font-weight: 400;
      font-size: 14px;
      &.red {
        color: @coral;
      }
    }
    span:not(.name) {
      color: @gray;
    }

  }
}
@primary-color: #0061D9;