@import "src/styles/vars";
.container {
  margin-top: 30px;
  max-width: 1400px;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
  @media (max-width: @sm) {
    justify-content: flex-start;
  }
}
.left {
  min-width: 350px;
  display: flex;
  align-items: center;
  gap: 15px;

  :global(.form-group) {
    margin-top: 0;
  }
  :global(.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child)) {
    border-radius: 5px 0 0 5px;
  }
  :global(.ant-input-affix-wrapper) {
    border-radius: 5px 0 0 5px;
  }
  :global(.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button) {
    border-radius: 0 5px 5px 0;
  }
  :global(.ant-input-search-button) {
    height: 47px;
  }
}
.filters {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  width: 40px;
  height: 47px;
  border-radius: 5px;
  color: @white;
  background-color: @green;
  cursor: pointer;
  transition: 0.3s ease all;
  margin-right: 15px;
  &:hover {
    background-color: @green-yellow;
  }
}
.clear {
  margin-right: 15px;
  height: 100%;
  :global(.ant-btn) {
    height: 100%;
    border-radius: 5px;
    color: @blue;
    border-color: @blue;
    &:hover {
      color: @dark-blue;
      border-color: @dark-blue;
    }
  }
}
.count {
  color: @gray-background;
}
.view {
  display: flex;
  gap: 8px;
  :global(.ant-btn) {
    border-radius: 5px;
    height: 47px;
  }
}
@primary-color: #0061D9;