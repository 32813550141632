@import "src/styles/vars";
.root {
  width: 652px;
}
.project {
  position: relative;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(222, 232, 245, 0.8);
  border-bottom: 1px solid #d7dfe9;
  padding: 8px 16px;
  .right {
    display: flex;
    gap: 20px;
  }
  .status {
    padding: 3px 5px;
    border-radius: 3px;
    color: @blue;
    background-color: #fff;
    line-height: 13px;
  }
  .details {
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
    color: @blue;
    transition: 0.3s ease all;
    &:hover {
      color: @dark-blue;
    }
  }
}
.name {
  max-width: 350px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.link {
  cursor: pointer;
  transition: 0.3s ease all;
  color: @main;
  user-select: none;
  &:hover {
    color: @dark-blue;
  }
}
.sprint {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #efeeee;
  padding: 8px 16px;
  &:last-child {
    border-bottom: none;
  }
  &__left {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  &__right {
    display: flex;
    gap: 10px;
  }
  .dot {
    width: 9px;
    height: 9px;
    border-radius: 50%;
  }
}
.stage {
  display: flex;
  align-items: center;
  gap: 5px;
}
.task {
  display: flex;
  align-items: center;
  background: #DEE8F5;
  color: @blue;
  font-size: 10px;
  line-height: 12px;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  white-space: nowrap;
}

@primary-color: #0061D9;