@import "src/styles/vars";
.reactour__popover {
  padding: 20px 15px 10px !important;
  font-size: 14px;
  line-height: 18px;
  span:not(.anticon) {
    background: @green !important;
  }
}
.close__popover {
  position: absolute;
  top: 0;
  right: 3px;
  font-size: 16px;
  line-height: 16px;
  color: @green !important;
  border-color: transparent!important;
  background: transparent!important;
  box-shadow: none!important;
}
