@import 'src/styles/vars';
.container {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: 15px 5px;
  grid-template-areas:
    "contentThematics_label contentThematics_label contentThematics  contentThematics contentThematics contentThematics contentThematics contentThematics"
    "tagsProjectTheme_label tagsProjectTheme_label tagsProjectTheme tagsProjectTheme tagsProjectTheme tagsProjectTheme tagsProjectTheme tagsProjectTheme"
    "tagsProject_label tagsProject_label tagsProject tagsProject tagsProject tagsProject tagsProject tagsProject"
    "tagsAssignmentCollection_label tagsAssignmentCollection_label tagsAssignment1 tagsAssignment1 tagsAssignment1 tagsAssignment2 tagsAssignment2 tagsAssignment2"
    "empty empty tagsCollection1 tagsCollection1 tagsCollection1 tagsCollection2 tagsCollection2 tagsCollection2"
    "isContentTaggingRequired_label . isContentTaggingRequired isContentTaggingRequired isContentTaggingRequired isContentTaggingRequired isContentTaggingRequired isContentTaggingRequired"
    "region_label region_label region1 region1 region1 region2 region2 region2"
    "contentDirection_label contentDirection_label contentDirection contentDirection contentDirection contentDirection . ."
    "contentFormatsCounted_label contentFormatsCounted_label contentFormatsCounted contentFormatsCounted contentFormatsCounted contentFormatsCounted contentFormatsCounted contentFormatsCounted"
    "contentFormatsUncounted_label contentFormatsUncounted_label contentFormatsUncounted contentFormatsUncounted contentFormatsUncounted contentFormatsUncounted contentFormatsUncounted contentFormatsUncounted"
    "workingMaterials_label workingMaterials_label workingMaterials workingMaterials workingMaterials workingMaterials workingMaterials workingMaterials"
    "dates_label dates_label dates dates dates dates dates dates";
  @media(max-width: 1356px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
    "contentThematics_label contentThematics_label contentThematics  contentThematics contentThematics contentThematics  contentThematics contentThematics"
    "tagsProjectTheme_label tagsProjectTheme_label tagsProjectTheme tagsProjectTheme tagsProjectTheme tagsProjectTheme tagsProjectTheme tagsProjectTheme"
    "tagsProject_label tagsProject_label tagsProject tagsProject tagsProject tagsProject tagsProject tagsProject"
    "tagsAssignmentCollection_label tagsAssignmentCollection_label tagsAssignment1 tagsAssignment1 tagsAssignment1 tagsAssignment2 tagsAssignment2 tagsAssignment2"
    "empty empty tagsCollection1 tagsCollection1 tagsCollection1 tagsCollection2 tagsCollection2 tagsCollection2"
    "isContentTaggingRequired_label . isContentTaggingRequired isContentTaggingRequired isContentTaggingRequired isContentTaggingRequired isContentTaggingRequired isContentTaggingRequired"
    "region_label region_label region1 region1 region1 region2 region2 region2"
    "contentDirection_label contentDirection_label contentDirection contentDirection contentDirection contentDirection . ."
    "contentFormatsCounted_label contentFormatsCounted_label contentFormatsCounted contentFormatsCounted contentFormatsCounted contentFormatsCounted contentFormatsCounted contentFormatsCounted"
    "contentFormatsUncounted_label contentFormatsUncounted_label contentFormatsUncounted contentFormatsUncounted contentFormatsUncounted contentFormatsUncounted contentFormatsUncounted contentFormatsUncounted"
    "workingMaterials_label workingMaterials_label workingMaterials workingMaterials workingMaterials workingMaterials workingMaterials workingMaterials"
    "dates_label dates_label dates dates dates dates dates dates";
  }
  @media(max-width: 980px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
    "contentThematics_label contentThematics_label contentThematics_label"
    "contentThematics contentThematics contentThematics"
    "tagsProjectTheme_label tagsProjectTheme_label tagsProjectTheme_label"
    "tagsProjectTheme tagsProjectTheme tagsProjectTheme"
    "tagsProject_label tagsProject_label tagsProject_label"
    "tagsProject tagsProject tagsProject"
    "tagsAssignmentCollection_label tagsAssignmentCollection_label tagsAssignmentCollection_label"
    "tagsAssignment1 tagsAssignment1 tagsAssignment1"
    "tagsAssignment2 tagsAssignment2 tagsAssignment2"
    "tagsCollection1 tagsCollection1 tagsCollection1"
    "tagsCollection2 tagsCollection2 tagsCollection2"
    "isContentTaggingRequired_label isContentTaggingRequired_label isContentTaggingRequired_label"
    "isContentTaggingRequired isContentTaggingRequired isContentTaggingRequired"
    "region_label region_label region_label"
    "region1 region1 region1"
    "region2 region2 region2"
    "contentDirection_label contentDirection_label contentDirection_label"
    "contentDirection contentDirection contentDirection"
    "contentFormatsCounted_label contentFormatsCounted_label contentFormatsCounted_label"
    "contentFormatsCounted contentFormatsCounted contentFormatsCounted"
    "contentFormatsUncounted_label contentFormatsUncounted_label contentFormatsUncounted_label"
    "contentFormatsUncounted contentFormatsUncounted contentFormatsUncounted"
    "workingMaterials_label workingMaterials_label workingMaterials_label"
    "workingMaterials workingMaterials workingMaterials"
    "dates_label dates_label dates_label"
    "dates dates dates";
  }
  @media(max-width: 600px) {
    grid-template-rows: auto;
    grid-template-columns: 1fr;
  }
  :global {
    .ant-btn-link:hover, .ant-btn-link:focus {
      border-color: transparent;
    }
  }
}
.text_right {
  text-align: right;
  @media(max-width: 900px) {
    text-align: left;
  }
}
.checkboxInSelect {
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 5px;
  width: 100%;
  font-size: 14px;
  border-bottom: 1px solid rgba(@gray, 0.3);
}
.checkboxText {
  font-size: 12px;
}
.btnAdd {
  width: 100%;
}
.labelSmall {
  font-size: 14px;
}
.delete {
  color: @coral;
  height: 43px;
  font-size: 16px;
  &:hover {
    color: @dark-coral;
  }
}

.contentThematics_label { grid-area: contentThematics_label; }
.contentThematics { grid-area: contentThematics; }

.tagsProject_label { grid-area: tagsProject_label; }
.tagsProject { grid-area: tagsProject; }

.tagsProjectTheme_label { grid-area: tagsProjectTheme_label; }
.tagsProjectTheme { grid-area: tagsProjectTheme; }

.tagsAssignmentCollection_label { grid-area: tagsAssignmentCollection_label; }
.tagsAssignment1 { grid-area: tagsAssignment1; }
.tagsAssignment2 { grid-area: tagsAssignment2; }
.tagsCollection1 { grid-area: tagsCollection1; }
.tagsCollection2 { grid-area: tagsCollection2; }

.isContentTaggingRequired { grid-area: isContentTaggingRequired; }
.isContentTaggingRequired_label { grid-area: isContentTaggingRequired_label; }

.region1 { grid-area: region1; }
.region2 { grid-area: region2; }
.region_label { grid-area: region_label; }

.contentDirection { grid-area: contentDirection; }
.contentDirection_label { grid-area: contentDirection_label; }

.contractNumber { grid-area: contractNumber; }
.contractNumber_label { grid-area: contractNumber_label; }

.contentFormatsCounted {
  grid-area: contentFormatsCounted;
}
.contentFormatsUncounted {
  grid-area: contentFormatsUncounted;
}
.workingMaterials {
  grid-area: workingMaterials;
}
.contentFormatsCounted_label { grid-area: contentFormatsCounted_label; }
.contentFormatsUncounted_label { grid-area: contentFormatsUncounted_label; }
.workingMaterials_label { grid-area: workingMaterials_label; }

.dates {
  grid-area: dates;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.dates_label { grid-area: dates_label; }

.checkbox {
  margin-top: 10px;
}
.contentFormats {
  :global(.ant-select-selector) {
    max-height: 39px;
  }
}

.error {
  margin-top: -20px;
}

@primary-color: #0061D9;