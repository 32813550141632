@import 'src/styles/vars';

.filters {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	grid-gap: 8px;
	gap: 8px;
	padding: 8px 0;
}
.name {
	display: flex;
	align-items: center;
	grid-gap: 8px;
	gap: 8px;
	border-radius: 3px;
	padding: 4px 8px;
	color: #333333;
	background-color: #d9d9de;
	cursor: pointer;
	user-select: none;
	&:global(.active) {
		background-color: rgba(0, 97, 217, 0.2);
	}
}
.icon {
	color: @coral;
	margin-left: 5px;
}

@primary-color: #0061D9;