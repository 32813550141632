@import "src/styles/vars";

  .container {
    display: flex;
    justify-content: space-between;

  }

  :global(.anticon) {
     margin-left: 3px;
  }

  .direction_btn {
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .sorting {
    max-width: 250px;
    margin-bottom: 40px;
    background-color:  rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    border: 1px solid #d9d9d9;
    overflow: hidden;
    :global(.ant-radio-group) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    :global(.ant-radio + span) {
      color: @gray-background;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
    }
    .radio {
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;

    }

    .radio:global(.ant-radio-wrapper-checked .ant-radio + span) {
      color: @white;
    }
    :global(.radio.ant-radio-wrapper-checked.ant-radio-wrapper-disabled) {
      background-color: @gray-background
    }
    :global(.radio.ant-radio-wrapper-checked) {
      border-radius: 6px;
    }
    :global(.ant-radio) {
      display: none;
    }
    .radio:global(.ant-radio-wrapper-checked) {
      background-color: @blue!important;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15),
        0 -4px 4px rgba(0, 0, 0, 0.05) !important;
    }

  }

@primary-color: #0061D9;