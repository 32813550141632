@import "src/styles/vars";
.container {
  width: 100%;
  display: flex;
}
.icon {}
.removeRelation {
  position: absolute;
  right: 15px;
  color: @coral;
}
.select {
  &:global(.ant-select) {
    width: 100%;
    :global(.ant-select-selection-item) {
      .icon {
        display: none;
      }
      .option__label {
        max-width: 100%;
      }
    }
  }
  :global(.ant-select-clear) {
    margin-right: 18px;
  }
}
.option {
  position: relative;
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: space-between;
  .option__label {
    //color: #8B9DAB;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 90%;
  }
}
.dropdown {
  .removeRelation {
    display: none;
  }
}
.buttons {
  position: absolute;
  right: 2px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 15px;
  .editBtn {
    color: @blue;
  }
  .deleteBtn {
    color: @coral;
  }
  .save {
    color: @green;
    &:global(.disabled) {
      color: @gray-background;
      cursor: not-allowed;
    }
  }
  .cancel {
    color: @coral;
  }
}
.newBtn {
  display: flex;
  align-items: center;
  gap: 4px;
  transition: 0.3s ease all;
  width: 100%;
  padding: 4px 8px;
  cursor: pointer;
  color: #8B9DAB;
  &:hover {
    color: @blue;
  }
}
.editFeel {
  width: 90%;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px;
}
@primary-color: #0061D9;