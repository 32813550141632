@import "src/styles/vars";

.table {
  width: 100%;
  .ant-table-thead > tr > th {
    background: @light-fill;
    color: @black;
    font-weight: 700;
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    padding: 15px;
    text-align: center;
  }
  .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: @light-fill;
  }
  .ant-table-cell-fix-sticky {
    background: @light-fill !important;
  }
}
