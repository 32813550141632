@import 'src/styles/vars';

.user_guide {
  width: 100%;
  display: flex;
  margin-top: 40px;
  margin-bottom: 40px;
  justify-content: space-around;
  align-items: center;
  :global(.anticon) {
    font-size: 28px;
    margin-right: 7px;

  }
  .item {
    display: flex;
    align-items: center;
    transition: 0.3s ease-in-out all;
    font-weight: 400;
    font-size: 15px;
    &:hover {
      color: rgba(@dark-blue, 1);
      transform: scale(1.03);

      a {
        color: rgba(@dark-blue, 1);
      }
    }
    a {
      display: flex;
      align-items: center;
      color: @main;
      span {
        svg {
          color: @blue;
        }
      }
    }
  }
}

.text {
  margin-top: 20px;
  margin-left: 20px;
  margin-bottom: 20px;
  font-size: 20px;
  color: @dark-blue;
}


@primary-color: #0061D9;