.ContentPage {
  padding-left: 15px;
  padding-right: 15px;
  min-height: calc(100vh - 110px);

  &__list {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(237px, 1fr));
    grid-template-rows: repeat(auto-fit, minmax(285px, 1fr));
    gap: 15px 15px;

    .card {
    }

    .ant-pagination {
      display: flex;
      align-items: center;
    }
  }

  &__pagination {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    padding-bottom: 30px;
  }
}
@primary-color: #0061D9;